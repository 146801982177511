import axios from "axios";
import React, { useEffect, useState } from "react";
import { getCashbackCustomersUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import SubscriptionInactive from "../components/SubscriptionInactive";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import FundCashbackDialog from "../modals/FundCashbackDialog";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const CashbackCustomers = () => {
  const [cashbackCustomers, setCashbackCustomers] = useState([]);
  const [period, setPeriod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openFundCashback, setOpenFundCashback] = useState(null);

  const fetchCashbacks = async (timeperiod) => {
    const newData = {
      ...UserProfileData(),
      period: moment(timeperiod).format("YYYY-MM-DD")
    };
    setIsLoading(true);
    await axios
      .post(getCashbackCustomersUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const cashbackCustomers = response["data"]["data"];

        setCashbackCustomers(cashbackCustomers);
      });
  };

  useEffect(() => {
    fetchCashbacks(new Date());
    // eslint-disable-next-line
  }, []);

  const getCurrentMonth = () => {
    const monthName = period?.toLocaleString("default", { month: "long" });
    return monthName;
  };

  useEffect(() => {
    setPeriod(new Date());
  }, []);

  let current_month = getCurrentMonth();

  const handleChange = (date) => {
    setPeriod(date);
    fetchCashbacks(date);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <FundCashbackDialog 
            employee_name={openFundCashback?.fullname}
            amount={openFundCashback?.cashback_value}
            customer_id={openFundCashback?.user_id}
            period={moment(period).format("YYYY-MM-DD")}
            isModalVisible={openFundCashback !== null}
            handleCloseDialog={() => setOpenFundCashback(null)}
            fetchCashbacks={fetchCashbacks}
          />
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>{current_month} Cashback Eligible Customers </h5>
            <span>Home</span>
          </div>
          <div className="container-fluid shipments-container">
            <div className="card bg-light border-0 shadow p-4 min-vh-100">
              <div className="my-3 row justify-content-end align-items-end">
                <div className="col-md-4 col-12">
                  <label htmlFor="">Select Cashback Period</label>
                  <DatePicker
                    className="form-control"
                    selected={period}
                    showMonthYearPicker
                    onChange={handleChange}
                    dateFormat="MM/yyyy"
                  />
                </div>
                {/* <div className="col-md-2 col-6">
                  <button
                    style={{ marginRight: 15 }}
                    className="confirm-button"
                    onClick={fetchCashbacks}
                  >
                    FETCH
                  </button>
                </div> */}
              </div>
              <table className="shipments-table">
                <thead>
                  <tr>
                    <th scope="col">Customer</th>
                    <th scope="col">Total Spent</th>
                    <th scope="col">Eligible Cashback</th>
                    <th scope="col">Cashback Payment</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {isLoading === true ? null : (
                  <tbody>
                    {cashbackCustomers.map((cashback) => {
                      // const status = shipment.status !== "" ? shipment.status : "Pending Delivery";

                      return (
                        <tr key={cashback.id}>
                          <td>{cashback.fullname}</td>
                          <td>
                            ₦ {parseFloat(cashback.sm).toLocaleString("en")}
                          </td>
                          <td>{cashback.cashback_name}</td>
                          <td>
                            ₦{" "}
                            {parseFloat(cashback.cashback_value).toLocaleString(
                              "en"
                            )}
                          </td>
                          <td>{cashback.payment_status}</td>
                          <td>
                            <button
                              className="confirm-button"
                              style={{background: cashback.payment_status === "paid" && "#D7D7D7"}}
                              disabled={cashback.payment_status === "paid"}
                              onClick={() => setOpenFundCashback(cashback)}
                            >
                              FUND USER
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashbackCustomers;
