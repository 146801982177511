import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useState, useEffect, useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import {getAllCustomerWalletURL, getTransactionsURL, searchForCustomersURL} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import {GoPrimitiveDot} from "react-icons/go";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import {BsDot} from "react-icons/bs";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const CustomersWallet = () => {
    const form = useRef(null);
    const navigator = useNavigate();
    const params = useParams();

    const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
    const [chosenDate, setChosenDate] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);
    const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] = useState(false);

    const [isQuerySearching, setIsQuerySearching] = useState(false);
    const [resultContainerVisibility, setResultContainerVisibility] = useState(false);
    const [customerQueryResults, setCustomerQueryResults] = useState([]);


    const [isOptionSelected, setIsOptionSelected] = useState("");

    const [transactions, setShipments] = useState([]);

    const data = {
        limit: 20,
        offset: currentOffset,
        payment_status : chosenPaymentStatus,
        date : chosenDate
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getAllCustomerWalletURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(newData);
                console.log(response);
                if (isLoading === false && isRetrievingMore === false){
                    console.log("Should not retrieve");
                    return;
                }
                console.log(response);
                setIsRetrievingMore(false)
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newShipments = response["data"]["data"];

                if (newShipments.length > 0){
                    const index = newShipments[newShipments.length - 1].id;

                    setCurrentOffset(index);
                }

                if (currentOffset === 0){
                    setShipments(newShipments);
                } else {
                    setShipments([...transactions, ...newShipments]);
                }
            });
    }, [isRetrievingMore, isOptionSelected]);

    const handleClick = (customerID) => {
        navigator("/customer-wallet-history/" + customerID);
    };

    const setScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && isRetrievingMore === false){
            setIsRetrievingMore(true);
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const exportToCSV = () => {
        if (transactions.length === 0)
            return;

        let csvContent = "data:text/csv;charset=utf-8,";

        csvContent += "Shipment ID,Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Weight"  + "\r\n";

        transactions.forEach(function(shipment) {
            let id = shipment.id;

            let senderDetails = shipment.sender_name + "," + shipment.sender_phone_no
                + "," + shipment.pick_up_location.replaceAll(",", "");
            let receiverDetails = shipment.recipient_name + "," + shipment.recipient_phone_no
                + "," + shipment.drop_off_location.replaceAll(",", "");
            let packageDetails = shipment.standard_fee.replaceAll(",", "") + ","
                + shipment.weight;

            const row = id + "," +senderDetails + "," + receiverDetails + "," + packageDetails;

            csvContent += row + "\r\n";
        });

        const encodedURI = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedURI)
        link.setAttribute("download", "shipments1.csv")
        document.body.appendChild(link)

        link.click();
    }

    const onChangePaymentStatus = event => {
        setChosenPaymentStatus(event.target.value)
    }

    const toggleCustomDateRangeDialog= () => {
        setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
    }

    const searchForCustomers = (e) => {
        e.preventDefault();
    }

    const handleQueryChange = () => {
        const formData = new FormData(form.current);
        const query = formData.get("query");

        if (query.toString().trim() === ""){
            setIsQuerySearching(false);
            setResultContainerVisibility(false);

            return null;
        }

        const formValues = {}
        formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = { ...formValues, ...UserProfileData() };

        setIsQuerySearching(true);
        setResultContainerVisibility(true);

        axios
            .post(searchForCustomersURL, newData)
            .then(function (response){
                console.log(response);

                if (response["data"]["success"] === false) return;

                const customers = response["data"]["data"];

                setCustomerQueryResults(customers);
            });
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />
                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>Customers Wallet</h5>
                        <span>Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                        <CustomDateRangeDialog
                            isModalVisible={isCustomDateRangeDialogVisible}
                            handleCloseDialog={(e) => {
                                if (typeof e === "string"){
                                    console.log("The chosen date is " + e.toString());
                                    setChosenDate(e);
                                }
                                toggleCustomDateRangeDialog("close");
                            }}
                        />

                        <div className="card bg-light border-0 shadow p-4 min-vh-100">

                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <form className="query-result-container" style={{width:"100%"}} ref={form} onSubmit={searchForCustomers}>
                                        <input onChange={handleQueryChange} name="query" className="form-control" type="text" placeholder="Search using customer's name or number"/>
                                        <div className={`query-result ${resultContainerVisibility === true ? "result-visible" : null}`}>
                                            {setIsQuerySearching === true ? null :
                                                customerQueryResults.map((customer) => {
                                                    return(
                                                        <div key={customer.id} className="d-flex flex-column" onClick={() => handleClick(customer.id)}>
                                                            <span style={{fontSize:15}}>{customer.fullname}</span>
                                                            <span className="d-flex align-items-center">
                                                    <span style={{fontSize:13}}>{customer.phone}</span>
                                                    <BsDot/>
                                                    <span style={{fontSize:13}}>{customer.email}</span>
                                                </span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </form>
                                </div>

                                <input className="confirm-button" type="button" onClick={exportToCSV} value="EXPORT TO CSV" />
                            </div>

                            <table className="shipments-table">
                                <thead>
                                <tr>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Total Funded</th>
                                    <th scope="col">Total Spent</th>
                                    <th scope="col">Total Withdrawn</th>
                                </tr>
                                </thead>
                                {isLoading === true ? null : (
                                    <tbody>
                                    {transactions.map((transaction) => {
                                        return (
                                            <tr key={transaction.id} onClick={() => handleClick(transaction.user_id)}>
                                                <td>{transaction.user_name}</td>
                                                <td>
                                                    ₦{parseFloat(transaction.account_balance).toLocaleString(
                                                    "en"
                                                    )}
                                                </td>
                                                <td>
                                                    ₦
                                                    {parseFloat(transaction.total_funded).toLocaleString(
                                                        "en"
                                                    )}
                                                </td>
                                                <td>
                                                    ₦
                                                    {parseFloat(transaction.total_spent).toLocaleString(
                                                        "en"
                                                    )}
                                                </td>
                                                <td>
                                                    ₦
                                                    {parseFloat(transaction.total_withdrawn).toLocaleString(
                                                        "en"
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                )}
                            </table>
                            {isRetrievingMore === false ? null :
                                <div style={{textAlign:"center", marginTop:40}}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/> Retrieving More..
                                </div>
                            }

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomersWallet;
