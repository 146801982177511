import "../../styles/home.scss";
import "../../styles/reports.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import {
  getReportUsersURL,
  getShipmentsURL,
  getUserReportsURL,
} from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import dataIcon from "../../images/no-data.png";

import { Dropdown } from "react-bootstrap";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";
import * as ExcelJS from "exceljs";
const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const CustomerReports = () => {
  const navigator = useNavigate();

  const [reportType, setReportType] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [isOptionSelected, setIsOptionSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");

  const [shipments, setShipments] = useState([]);

  const data = {
    limit: 1000,
    offset: currentOffset,
    report_type: reportType,
    report_time: reportTime,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  const getReports = () => {
    if (reportTime !== "" && reportType !== "") {
      setIsLoading(true);
      setCurrentOffset(0);
      setIsOptionSelected(reportType + reportTime);

      getCustomerReports();
    }
  };

  const getCustomerReports = () => {
    const {
      user_id,
      company_id,
      user_ses_id,
      transaction_id,
      mode,
      report_time,
    } = newData;
    const submitData = {
      user_id,
      company_id,
      transaction_id,
      mode,
      user_ses_id,
      type: report_time,
    };
    setIsLoading(true);
    axios
      .post(getReportUsersURL, submitData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsRetrievingMore(false);
        setIsOptionSelected("selected");
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newShipments = response["data"]["data"];

        const index = newShipments[newShipments.length - 1].id;

        setCurrentOffset(index);

        if (currentOffset === 0) {
          setShipments(newShipments);
        } else {
          setShipments([...shipments, ...newShipments]);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onChangeReportType = (event) => {
    setReportType(event.target.value);
  };

  const onChangeReportTime = (event) => {
    setReportTime(event.target.value);
  };

  const handleClick = (customerID) => {
    navigator("/customer/" + customerID);
  };

  const setScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      isRetrievingMore === false
    ) {
      setIsRetrievingMore(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Report");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "CUSTOMER ID",
        key: "id",
        width: 30,
      },
      {
        header: "CUSTOMER NAME",
        key: "fullname",
        width: 80,
      },
      {
        header: "EMAIL ADDRESS",
        key: "email",
        width: 50,
      },
      {
        header: "PHONE NUMBER",
        key: "phone",
        width: 40,
      },
      {
        header: "REFERRAL CODE",
        key: "ref_code",
        width: 40,
      },
      {
        header: "MEMBER TYPE",
        key: "member_type",
        width: 40,
      },
      { header: "LAST ACTIVITY", key: "last_activity", width: 50 },
      { header: "DATE CREATED", key: "date_time", width: 50 },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
    CUSTOMER REPORT
    `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    shipments?.forEach((shipment, id) => {
      sheet.addRow({
        sn: id + 1,
        id: `${shipment.id ?? "N/A"}`,
        fullname: `${shipment?.fullname ?? "N/A"}`,
        email: `${shipment?.email ?? "N/A"}`,
        phone: `${shipment?.phone ?? "N/A"}`,
        ref_code: `${shipment?.ref_code ?? "N/A"}`,
        member_type: `${shipment?.member_type ?? "N/A"}`,
        last_activity: `${shipment?.last_activity ?? "N/A"}`,
        date_time: `${shipment?.date_time ?? "N/A"}`,
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Customer_Report.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "ID, Customer Name,Email Address,Phone Number, Referral Code, Member Type, Last Activity, Date Created" +
      "\r\n";

    shipments.forEach(function (shipment) {
      let row;
      row =
        shipment.id +
        "," +
        shipment.fullname +
        "," +
        shipment.email +
        "," +
        shipment.phone +
        "," +
        shipment.ref_code +
        "," +
        shipment.member_type +
        "," +
        shipment.last_activity +
        "," +
        shipment.date_time;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "customer-reports.csv");
    document.body.appendChild(link);

    link.click();
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>Customer Reports</h5>
            <span>Home</span>
          </div>
          <div className="container shipments-container">
            <div className="card bg-light border-0 shadow p-4 min-vh-100">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-end">
                  {/* <select onChange={onChangeReportType} style={{width:230, marginRight:10}} name="pickup_detail_id" className="form-select" >
                    <option value="">Choose report</option>
                    <option value="shipments per customer">Total Shipments Per Customer</option>
                    <option value="revenue per customer">Total Revenue Per Customer</option>
                  </select> */}

                  <select
                    onChange={onChangeReportTime}
                    style={{ width: 200, marginRight: 10 }}
                    name="pickup_detail_id"
                    className="form-select"
                  >
                    <option value="" hidden>
                      Choose date
                    </option>
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                    <option value="Year">This Year</option>
                    <option value="custom">Custom Date</option>
                  </select>

                  {reportTime === "custom" && (
                    <div className="d-flex gap-3">
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-select"
                          value={reportStartDate}
                          onChange={(e) => setReportStartDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-select"
                          value={reportEndDate}
                          onChange={(e) => setReportEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    onClick={getCustomerReports}
                    className="confirm-button"
                  >
                    {isLoading ? (
                      <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    ) : (
                      <BiIcons.BiSearch style={{ fontSize: 20 }} />
                    )}
                  </button>
                </div>

                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    EXPORT
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={exportToCSV}>
                      Export To CSV
                    </Dropdown.Item>
                    <Dropdown.Item onClick={exportToXlsx}>
                      Export To Spreadsheet
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <table className="reports-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Reference Code</th>
                    <th scope="col">Last Activity</th>
                    <th scope="col">Created At</th>
                  </tr>
                </thead>
                {isLoading === true ? null : (
                  <tbody>
                    {shipments.map((shipment) => {
                      const created_dateTime = moment(shipment.date_time);
                      const created_timestamp =
                        created_dateTime.format("ddd MMM Do, h:mm A");

                      const activity_dateTime = moment(shipment.last_activity);
                      const activity_timestamp =
                        activity_dateTime.format("ddd MMM Do, h:mm A");
                      return (
                        <tr
                          key={shipment.id}
                          onClick={() => handleClick(shipment.id)}
                        >
                          <td>{shipment.fullname}</td>
                          <td>{shipment.email}</td>
                          <td>{shipment.phone}</td>
                          <td>{shipment.ref_code}</td>
                          <td>{activity_timestamp}</td>
                          <td>{created_timestamp}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {isRetrievingMore === false ||
              isOptionSelected === "" ||
              isLoading === true ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}
              {isOptionSelected !== "" || isLoading ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReports;
