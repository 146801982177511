import * as React from "react";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../library/constants";
import axios from "axios";
import {getShipmentTimelineURL, getShipmentTrackingNumbersURL} from "../../library/URLs";
import moment from "moment/moment";
import { BsLink } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DHLShipmentContainer = ({processor, dhlProcessed, waybillURL, trackingNumber,
  invoiceURL, status, shipmentID}) => {

    const [trackingNumbers, setTrackingNumbers] = useState([]);

    const data = {
        shipment_id: shipmentID,
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getShipmentTrackingNumbersURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                if (response["data"]["success"] === false) return;

                var timeline = response["data"]["data"];

                setTrackingNumbers(timeline);
            });
    }, []);

  return (
    <div className="col-xl-6 col-md-12">
      <span className="header">Processor Shipment Details</span>
      <div className="card bg-light border-0 shadow">
        <div className="card-body shipper-container">
          <div style={{ width: "100%" }}>
              <Processed
                  trackingNumber={trackingNumber}
                  waybillURL={waybillURL}
                  invoiceURL={invoiceURL}
                  status = {status}
                  shipmentTrackingNumbers={trackingNumbers}
              />
            {/*{dhlProcessed === "Yes" ? (*/}
            {/*  <Processed*/}
            {/*    trackingNumber={trackingNumber}*/}
            {/*    waybillURL={waybillURL}*/}
            {/*    invoiceURL={invoiceURL}*/}
            {/*    status = {status}*/}
            {/*    shipmentTrackingNumbers={trackingNumbers}*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <NotProcessed>*/}
            {/*      <p>Oops... Sorry, you are yet to process this shipment</p>*/}
            {/*  </NotProcessed>*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

const NotProcessed = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Processed = ({ waybillURL, trackingNumber, invoiceURL, status, shipmentTrackingNumbers}) => {
    console.log(waybillURL);
    const [copied, setCopied] = useState(null);

    const handleCopy = (id) => {
      setCopied(id);

      setTimeout(() => {
        setCopied(null);
      }, 2000)
    };
  return (
    <div>
        <div>
            <span className="title">Invoice</span>
            <p>
                Click{" "}
                <a style={{textDecoration:"none"}} target="_blank" href={invoiceURL}>
                    here
                </a>{" "}
                to view the invoice
            </p>
        </div>
        {
            shipmentTrackingNumbers.map((trackingNumber, index) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" >
                      <div>
                        <span className="title">{trackingNumber.shipment_type} - {trackingNumber.tracking_number}</span>
                        <p>
                            Click{" "}
                            <a style={{textDecoration:"none"}} target="_blank" href={trackingNumber.extras}>
                                here
                            </a>{" "}
                            to view the invoice
                        </p>
                      </div>
                      <span style={{color: "#EE2463", cursor: "pointer", fontSize: 14}} >
                        <CopyToClipboard text={trackingNumber.extras} onCopy={() => handleCopy(index)}>
                          <span>
                            {
                              copied === index ? "Copied" : "Copy Link"
                            }{" "} <BsLink />
                          </span>
                        </CopyToClipboard>
                      </span>
                    </div>
                );
            })
        }
    </div>
  );
};

export default DHLShipmentContainer;
