import { Modal } from "react-bootstrap";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { TbPencil, TbPencilOff } from "react-icons/tb";
import axios from "axios";
import { updatePackageItemHSCodeURL } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const PackageItemsDialog = ({
    packages,
    isModalVisible,
    handleCloseDialog,
    shipmentNumber,
}) => {
    const [packageItems, setPackageItems] = useState([]);
    const [selectedHsCode, setSelectedHsCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        setPackageItems(packages);
    }, [packages]);

    const handleHsCode = (e, id) => {
        const { value } = e.target;

        let packageInfo = [...packageItems];
        packageInfo[id] = {
            ...packageInfo[id],
            hs_code: value,
        };
        console.log("df",packageInfo);
        setPackageItems(packageInfo);
    };

    const editHSCode = (e) => {
        e.preventDefault();

        let updatedPackageItem = [];
        packageItems?.forEach((packageItem) => {
            updatedPackageItem.push({
                item_id: packageItem?.id,
                hs_code: packageItem?.hs_code,
            });
        });

        const newData = {
            items: updatedPackageItem,
            shipment_number: shipmentNumber,
            ...UserProfileData(),
        };
        setIsLoading(true);

        axios
            .post(updatePackageItemHSCodeURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response["data"]);

                setIsLoading(false);

                if (response.data?.success) {
                    setSuccessVisibility(true);
                    setSelectedHsCode(null);
                    setSuccessMessage("HS Code updated successfully");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog();
                    }, 5000);
                }

                if (response.data?.success === false) {
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            });
    };

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog} size="xl">
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">
                    LIST OF PACKAGE ITEMS
                </h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}
                <div className="state-dialog-body">
                    <div className="d-flex justify-content-end mb-4">
                        <button className="confirm-button" type="submit" onClick={editHSCode} >
                            {isLoading ? (
                                <LoadingSpinner className="spinner-border spinner-grow-sm" />
                            ) : (
                                "EDIT HSCODE"
                            )}
                        </button>
                    </div>
                    <ul className="order-item-list">
                        <li className="order-item-row">
                            <div className="order-item-grid">
                                <p className="order-item-header first-order">Description/ Category</p>
                                <p className="order-item-header first-order">Unit</p>
                                <p className="order-item-header">Weight</p>
                                <p className="order-item-header">Quantity</p>
                                <p className="order-item-header">Value</p>
                                <p className="order-item-header">HS Code</p>
                            </div>
                        </li>
                        {packageItems?.length < 1 ? (
                            <p className="text-center py-4">No items available</p>
                        ) : (
                            packageItems?.map((packageItem, id) => (
                                <li key={id} className="order-item-row w-100">
                                    <div className="order-item-grid align-items-center">
                                        <p className="order-item-body first-order mb-0">
                                            {packageItem.name}
                                        </p>
                                        <p className="order-item-body  first-order mb-0">
                                            {packageItem.unit}
                                        </p>
                                        <p className="order-item-body mb-0">
                                            {packageItem.weight}KG
                                        </p>
                                        <p className="order-item-body mb-0">
                                            {packageItem.quantity}
                                        </p>
                                        <p className="order-item-body ps-1 mb-0">
                                            {packageItem.value !== "None" ? (
                                                packageItem.value ? (
                                                    <span>
                                                        {" "}
                                                        {packageItem?.value_currency === "USD" ? "$" : "₦"}
                                                        {packageItem.value}
                                                    </span>
                                                ) : (
                                                    "None"
                                                )
                                            ) : (
                                                "None"
                                            )}
                                        </p>
                                        <span className="order-item-body d-flex justify-content-between align-items-center gap-1">
                                            <input
                                                type="text"
                                                name="hs_code"
                                                onChange={(e) => handleHsCode(e, id)}
                                                className="form-control "
                                                value={packageItem?.hs_code}
                                                disabled={selectedHsCode !== id}
                                            />
                                            {selectedHsCode === id ? (
                                                <TbPencilOff
                                                    style={{ cursor: "pointer", fontSize: "18px" }}
                                                    onClick={() => setSelectedHsCode(null)}
                                                />
                                            ) : (
                                                <TbPencil
                                                    style={{ cursor: "pointer", fontSize: "18px" }}
                                                    onClick={() => setSelectedHsCode(id)}
                                                />
                                            )}
                                        </span>
                                    </div>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PackageItemsDialog;
