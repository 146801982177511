import { Modal } from "react-bootstrap";
import axios from "axios";
import {approveShipmentURL, cancelShipmentURL, makeRegularUserURL} from "../library/URLs";
import {useState} from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const CancelShipmentDialog = ({ isModalVisible, handleCloseDialog, shipmentID }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [refundPolicy, setRefundPolicy] = useState("");

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const cancelShipment = (e) => {
        const formValues = {};
        formValues['shipment_id'] = shipmentID;
        formValues["refund_policy"] = refundPolicy;

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(cancelShipmentURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment has been successfully cancelled");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog();
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">CANCEL SHIPMENT</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <p>You are about to cancel shipment</p>
          <p>This means the shipment can't be assigned and/or processed further.</p>
          <p>How would you like to continue?</p>

          <input name="refund_policy" defaultChecked={true}
                 className="form-check-input" type="radio" value="refund-wallet" checked={refundPolicy==="refund-wallet"} onChange={()=>setRefundPolicy("refund-wallet")}  />
          <label className="form-check-label" htmlFor="localShipment">
              Refund to customer wallet
          </label>

          <div style={{marginTop:10}}>
              <input name="refund_policy"
                     className="form-check-input" type="radio" value="refund-bank" checked={refundPolicy==="refund-bank"} onChange={()=>setRefundPolicy("refund-bank")}  />
              <label className="form-check-label" htmlFor="localShipment">
                  Refund directly to bank account
              </label>
          </div>

          <div style={{marginTop:10}}>
              <input name="refund_policy"
                     className="form-check-input" type="radio" value="no-refund" checked={refundPolicy==="no-refund"} onChange={()=>setRefundPolicy("no-refund")}  />
              <label className="form-check-label" htmlFor="localShipment">
                  Just cancel shipment
              </label>
          </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={cancelShipment} type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">CANCEL SHIPMENT</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelShipmentDialog;
