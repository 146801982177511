import "../styles/navbar.scss";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {getCompanySettingsURL, getDashboardStatisticsURL, getUnseenNotificationsURL, logoutURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {SessionManagement} from "../library/SessionManagement";
import {useNavigate} from "react-router-dom";
import { BsBell } from "react-icons/bs";
import LoadingBar from "react-top-loading-bar";
import {URLConnection} from "../library/URLConnection";
import * as BoxIcons from "react-icons/bi";


const Navbar = () => {
    const ref = useRef(null);
    const navigate = useNavigate();

    const [unseenNotifications, setUnseenNotifications] = useState("0");
    const [showingMenu, setShowingMenu] = useState(false);

    const profileImageURL = SessionManagement.getProfileImageURL();

    const toggleMenu = () => {
        setShowingMenu(!showingMenu);
    }

    const handleLogoutClick = (e) => {
        ref.current.continuousStart();

        axios
            .post(logoutURL, UserProfileData())
            .then(function (response) {
                SessionManagement.setName("");
                SessionManagement.setEmail("");
                SessionManagement.setCompanyID("");
                SessionManagement.setCompanyName("");
                SessionManagement.setProfileImageURL("");
                SessionManagement.setUserID("");
                SessionManagement.setSessionID("");

                SessionManagement.setAuthenticationStatus("no");

                navigate("/login");
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    useEffect(()=> {
        URLConnection(getUnseenNotificationsURL, UserProfileData(), function (response){
            if (response["data"]["success"] === false) return;

            const unseenNotifications = response["data"]["data"]["unseen_notifications"];

            setUnseenNotifications(unseenNotifications);
        });
    }, [])

    // document.addEventListener("mousedown", toggleMenu);

    useEffect(()=> {
        URLConnection(getCompanySettingsURL, UserProfileData(), function (response){
            if (response["data"]["success"] === false) return;

            const intracityShipmentProcessor = response["data"]['data']['intracity_shipment_processor'];
            const interstateShipmentProcessor = response["data"]['data']['interstate_shipment_processor'];
            const internationalShipmentProcessor = response["data"]['data']['international_shipment_processor'];
            const isWhatsappSet = response["data"]['data']['whatsapp'];
            const currentPlan = response["data"]['data']['current_plan'];

            console.log(currentPlan);

            SessionManagement.setCurrentPlan(currentPlan);
            SessionManagement.setIntracityShipmentProcessor(intracityShipmentProcessor);
            SessionManagement.setInterstateShipmentProcessor(interstateShipmentProcessor);
            SessionManagement.setInternationalShipmentProcessor(internationalShipmentProcessor);
            SessionManagement.setIsWhatsappSet(isWhatsappSet);

            console.log(response);
        });
    }, [])

    const goToNotifications = () => {
        navigate("/notifications");
    }

    return (
        <div className="navbar">
            <LoadingBar color="#0b613a" ref={ref} />
            <div className="navbar-wrapper">
                {/*<div className="card bg-light border-0 shadow d-flex flex-row flex-grow-1">*/}
                {/*    <div className="card-body d-flex flex-row">*/}
                {/*        <BoxIcons.BiSearch style={{fontSize:20}} />*/}
                {/*        <input className="search-input" placeholder="Search by name or number"/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div onClick={goToNotifications} className="icon-container">
                    <BsBell className="icon"/>
                    {unseenNotifications === "0" ? null : <div className="counter">{parseInt(unseenNotifications) > 9 ? "9+" : unseenNotifications}</div>}
                </div>


                <img
                    onClick={toggleMenu}
                    src={profileImageURL}
                    className="avatar"
                    alt=""
                />

                <div className={`profile-menu ${showingMenu ? "profile-menu-visible" : null}`}>
                    <span>Profile</span>
                    <span>Support</span>
                    <span onClick={handleLogoutClick}>Logout</span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
