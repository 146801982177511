import "../styles/home.scss";
import "../styles/single-customer.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {getCustomerDetailsURL, getCustomerStatsURL} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {BsDot} from "react-icons/bs";
import styled from "styled-components";
import * as React from "react";
import MakeContractUserDialog from "../modals/MakeContractUserDialog";
import MakeRegularUserDialog from "../modals/MakeRegularUserDialog";
import CustomerProfileShipments from "./Widgets/Customer/CustomerProfileShipments";
import contractIcon from "../images/contract-icon.png";
import CustomerOverview from "./Widgets/Customer/CustomerOverview";
import moment from "moment";
import CustomerWallet from "./Widgets/Customer/CustomerWallet";
import CustomerReferrals from "./Widgets/Customer/CustomerReferrals";
import SendEmailDialog from "../modals/SendEmailDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";


const ProfileImage = styled.img`
    border-radius:50%;
    width:150px;
    height:150px;    
`;

const CommunicationContainer = styled.div`
    padding:8px 8px;
    border-radius:5px;
    background-color:#E5E7E9;
    color:#2E4053;
    font-size:12px;
    height:35px;
    margin-right:3px;
    margin-left:3px;
    cursor:pointer
`;

const SingleCustomer = () => {
    const params = useParams();
    const customerID = params["customerID"];

    const [isContractUserModalVisible, setContractUserModalVisibility] = useState(false);
    const [isRegularUserModalVisible, setRegularUserModalVisibility] = useState(false);

    const [emailDialogVisibility, setEmailDialogVisibility] = useState(false);

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [memberType, setMemberType] = useState("");
    const [profileImageURL, setProfileImageURL] = useState("https://users.mylogisticsapp.com/engine/v1/actions/profiles/default-profile-image.png");
    const [registrationTimestamp, setRegistrationTimestamp] = useState("");
    const [lastActivityTimestamp, setLastActivityTimestamp] = useState("");

    const [numberOfShipments, setNumberOfShipments] = useState("...");
    const [numberOfTransactions, setNumberOfTransactions] = useState("...");

    const [activeTab, setActiveTab] = useState("CustomerOverview");

    const [primaryPickupAddress, setPrimaryPickupAddress] = useState("");
    const [secondaryPickupAddress, setSecondaryPickupAddress] = useState("");

    const[userActionMenuVisibility, setUserActionMenuVisibility] = useState(false);



    const toggleUserActionMenu = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
    }

    const toggleContractUserModal = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
        setContractUserModalVisibility(!isContractUserModalVisible);
    };

    const handleRegularUserModal = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
        setRegularUserModalVisibility(!isRegularUserModalVisible);
    }

    const toggleEmailDialog = (type) => {
        setEmailDialogVisibility(!emailDialogVisibility);
    }

    const handleCloseModal = (refresh) => {
        setContractUserModalVisibility(false);
        setRegularUserModalVisibility(false);
        setEmailDialogVisibility(false);

        if (refresh === "Refresh"){
            window.location.reload();
        }
    };

    const data = {
        customer_id: customerID,
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getCustomerDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response["data"]["success"] === false) return;

                const shipmentDetails = response["data"];

                const name = shipmentDetails["data"]["fullname"];
                const phoneNumber = shipmentDetails["data"]["phone"];
                const email = shipmentDetails["data"]["email"];
                const referralCode = shipmentDetails["data"]["ref_code"];
                const memberType = shipmentDetails["data"]["member_type"];
                const profileImageURL = shipmentDetails["data"]["profile_image_url"];

                const registrationTime = moment(shipmentDetails["data"]["date_time"]);
                const registrationTimestamp = registrationTime.format('ddd MMM Do, h:mm A');

                const lastActivityTime = moment(shipmentDetails["data"]["last_activity"]);
                const lastActivityTimestamp = lastActivityTime.format('ddd MMM Do, h:mm A');

                const address = shipmentDetails["data"]["primary_pickup_address"];

                setPrimaryPickupAddress(address);

                setName(name);
                setPhoneNumber(phoneNumber);
                setEmail(email);
                setReferralCode(referralCode);
                setMemberType(memberType);
                setProfileImageURL(profileImageURL);
                setRegistrationTimestamp(registrationTimestamp);
                setLastActivityTimestamp(lastActivityTimestamp);
            });
    }, []);

    useEffect(() => {
        axios
            .post(getCustomerStatsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response["data"]["success"] === false) return;

                const totalShipments = response["data"]["total_shipments"];
                const totalShipmentWorth = response["data"]["total_shipments_worth"];

                setNumberOfShipments(totalShipments);
                setNumberOfTransactions("₦" + (totalShipmentWorth === null ? "0" : parseFloat(totalShipmentWorth).toLocaleString("en")) );
            });
    }, []);

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Profile</h5>
                        <span>Home</span>
                    </div>

                    <MakeContractUserDialog
                        isModalVisible={isContractUserModalVisible}
                        handleCloseDialog={handleCloseModal}
                        customerID={customerID}
                        getContractDetails={true}
                    />

                    <MakeRegularUserDialog
                        isModalVisible={isRegularUserModalVisible}
                        handleCloseDialog={handleCloseModal}
                        customerID={customerID}
                    />

                    <SendEmailDialog
                        isModalVisible={emailDialogVisibility}
                        handleCloseDialog={handleCloseModal}
                        customerID={customerID}
                    />

                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-xl-12 col-md-12">
                                <span className="header">Customer Details</span>
                                <div className="card bg-light border-0 shadow">
                                    <div className="card-body">
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="d-flex flex-row">
                                                <ProfileImage src={profileImageURL} />
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex flex-row align-items-center" style={{textAlign: 'center'}}>
                                                        <span style={{fontSize:22, marginRight:10, fontWeight:"bold"}}>{name}</span>
                                                        {memberType === "Contract" ? <img style={{width:18, height:18, marginRight:5}} src={contractIcon} alt="" /> : null}
                                                        <p style={{margin:0}}>{memberType} Customer</p>
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <span>{email}</span>
                                                        <BsDot/>
                                                        <span>{phoneNumber}</span>
                                                    </div>
                                                    <div className="d-flex flex-row mt-4">
                                                        <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                                            padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                                            <span>{numberOfShipments}</span>
                                                            <span>Shipments</span>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                                            padding:"3px 10px 3px 10px", borderRadius:4}}>
                                                            <span>{numberOfTransactions}</span>
                                                            <span>Transactions</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <CommunicationContainer>
                                                    Send SMS
                                                </CommunicationContainer>
                                                <CommunicationContainer>
                                                    Send Notification
                                                </CommunicationContainer>
                                                <CommunicationContainer onClick={(e) => toggleEmailDialog("open")}>
                                                    Send Email
                                                </CommunicationContainer>
                                                <HiOutlineDotsVertical onClick={toggleUserActionMenu} style={{width:25, cursor:"pointer", marginTop:10}}/>
                                                <div className={`user-menu ${userActionMenuVisibility ? "user-menu-visible" : null}`}>
                                                    {memberType === "Regular" ?
                                                        (<span onClick={toggleContractUserModal}>Make Contract User</span>) :
                                                        (
                                                            <div>
                                                                <span onClick={handleRegularUserModal}>Make Regular User</span>
                                                                <span onClick={toggleContractUserModal}>Edit Contract Details</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <span onClick={(e)=> setActiveTab("CustomerOverview")} className={`profile-tab ${activeTab === "CustomerOverview" ? "active-profile" : null}`}>Overview</span>
                                            <span onClick={(e)=> setActiveTab("CustomerShipments")} className={`profile-tab ${activeTab === "CustomerShipments" ? "active-profile" : null}`}>Shipments</span>
                                            <span onClick={(e)=> setActiveTab("CustomerWallet")} className={`profile-tab ${activeTab === "CustomerWallet" ? "active-profile" : null}`}>Wallet</span>
                                            <span onClick={(e)=> setActiveTab("CustomerReferrals")} className={`profile-tab ${activeTab === "CustomerReferrals" ? "active-profile" : null}`}>Referrals</span>
                                            {/*<span className="profile-tab">Activities</span>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-xl-12 col-md-12">
                                <div className={`${activeTab === "CustomerOverview" ? "show-customer-tab" : "hide-customer-tab"}`}>
                                    <CustomerOverview primaryPickupAddress={primaryPickupAddress}
                                                      secondaryPickupAddress={secondaryPickupAddress}
                                                      lastActivityTimestamp={lastActivityTimestamp}
                                                      registrationTimestamp={registrationTimestamp} />
                                </div>
                                <div className={`${activeTab === "CustomerShipments" ? "show-customer-tab" : "hide-customer-tab"}`}>
                                    <CustomerProfileShipments customerID={customerID}/>
                                </div>
                                <div className={`${activeTab === "CustomerWallet" ? "show-customer-tab" : "hide-customer-tab"}`}>
                                    <CustomerWallet customerID={customerID}
                                                    customerName={name}
                                                    customerPhoneNumber={phoneNumber}/>
                                </div>
                                <div className={`${activeTab === "CustomerReferrals" ? "show-customer-tab" : "hide-customer-tab"}`}>
                                    <CustomerReferrals
                                        customerID={customerID}
                                        referralCode={referralCode} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleCustomer;
