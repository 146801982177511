import { useState, useRef, useEffect } from "react";
import {Col, Modal, Row} from "react-bootstrap";
import axios from "axios";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../../components/Notifications";
import {
  calculateDHLURL,
  createInternationalShipmentURL,
  getDHLPickupLocationsURL,
  getPackagingURL, searchDHLCityURL, searchForCustomersURL
} from "../../../library/URLs";
import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";
import { generateTransactionID } from "../../../library/utilities";
import { UserProfileData, countryCodes } from "../../../library/constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {BsDot} from "react-icons/bs";
import * as BiIcons from "react-icons/bi";

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right:15px;
  align-items: center;
  justify-content: center;
  background-color: #ee2463;
  cursor:pointer;
  padding-left:2px
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee2463;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const CreateInterstateDHLShipmentForm = () => {
  const navigator = useNavigate();

  const form = useRef(null);

  const [contentFields, setContentFields] = useState(3);

  const [showSenderSuggestions, setShowSenderSuggestions] = useState(false);
  const [senderSuggestions, setSenderSuggestions] = useState([]);

  const [senderCountryCode, setSelect] = useState("NG");

  const [receiverCountryCode, setReceiverCountryCode] = useState("NG");

  const [startDate, setStartDate] = useState(new Date());

  const [isCalculatingShipment, setIsCalculatingShipment] = useState(false);
  const [isCreatingShipment, setIsCreatingShipment] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [pickupLocations, setPickupLocations] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);

  const [receiverCity, setReceiverCity] = useState("");
  const [receiverCitySuggestions, setReceiverCitySuggestions] = useState([]);
  const [showReceiverCitySuggestions, setShowReceiverCitySuggestions] = useState(false);

  const [dhlPrice, setDHLPrice] = useState("0");
  const [operatorPrice, setOperatorPrice] = useState("0");

  const [isShipmentCalculated, setIsShipmentCalculated] = useState(false);
  const [showPhase2Shipment, setShowPhase2Shipment] = useState(false);

  const [senderName, setSenderName] = useState("");
  const [senderEmailAddress, setSenderEmailAddress] = useState("");
  const [senderPhoneNumber, setSenderPhoneNumber] = useState("");

  const [carriers, setCarriers] = useState([]);

  const [selectedCarrierName, setSelectedCarrierName] = useState("");
  const [selectedCarrierAmount, setSelectedCarrierAmount] = useState("");
  const [selectedCarrierInsurance, setSelectedCarrierInsurance] = useState("No");

  const selectSender = (customerName, customerNumber, emailAddress) => {
    setSenderName(customerName);
    setSenderEmailAddress(emailAddress);
    setSenderPhoneNumber(customerNumber);

    setShowSenderSuggestions(false);
  }

  const onChangeSenderDetails = (event, type) => {
    if (type === "senderEmailAddress"){
      setSenderEmailAddress(event.target.value);
    } else if (type === "senderPhoneNumber"){
      setSenderPhoneNumber(event.target.value);
    } else if (type === "senderName"){
      setSenderName(event.target.value);
      searchForCustomers(event);
    }

    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);
  }

  const onChangeReceiverDetails = (event, type) => {
    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);
  }

  useEffect(() => {
    axios
        .post(getDHLPickupLocationsURL, UserProfileData())
        .then(function (response) {
          console.log(response);
          if (response["data"]["success"] === true) {
            const pickupLocations = response["data"]["data"];
            console.log(pickupLocations);

            setPickupLocations(pickupLocations);
          }
        })
        .catch(function (error) {
          return error;
        });
  }, [])

  useEffect(() => {
    axios
        .post(getPackagingURL, UserProfileData())
        .then(function (response) {
          console.log(response);
          if (response["data"]["success"] === true) {
            const packaging = response["data"]["data"];
            console.log(packaging);

            setContainerTypes(packaging);
          }
        })
        .catch(function (error) {
          return error;
        });
  }, [])

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const createShipment = (e) => {
    e.preventDefault();

    clearErrorMessage();

    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;

    formValues["sender_postal_code"] = "11111";
    formValues["receiver_postal_code"] = "00000";

    formValues["amount"] = selectedCarrierAmount;
    formValues["carrier"] = selectedCarrierName;
    formValues["insurance"] = selectedCarrierInsurance;

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    setIsCreatingShipment(true);

    axios
      .post(createInternationalShipmentURL, newData)
      .then(function (response) {
        console.log(response);
        setIsCreatingShipment(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          window.scrollTo(0, 0);

          const shipmentID = response["data"]["data"]["shipment_id"];

          setSuccessVisibility(true);
          setSuccessMessage(
            "Shipment has been successfully created. Redirecting..."
          );

          setTimeout(() => {
            navigator("/shipment/" + shipmentID);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const calculateDHLPrice = () => {
    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;

    formValues["sender_postal_code"] = "11111";
    formValues["receiver_postal_code"] = "00000";

    const newData = { ...formValues, ...UserProfileData() };

    setIsCalculatingShipment(true);

    axios
        .post(calculateDHLURL, newData)
        .then(function (response) {
          console.log(response);
          setIsCalculatingShipment(false);
          if (response["data"]["success"] === true) {
            const dhlPrice = response["data"]["data"]["dhl_price"];
            const operatorPrice = response["data"]["data"]["operator_price"];

            setDHLPrice(dhlPrice);
            setOperatorPrice(operatorPrice);
            // setIsShipmentCalculated(true);

            const carriers = response["data"]["data"]["carriers"];
            setShowPhase2Shipment(true);

            console.log(carriers);

            carriers.forEach((carrier) => {
              carrier["selected"] = false;
            });
            setCarriers(carriers);
          } else {
            window.scrollTo(0, 0);
            setErrorVisibility(true);
            setErrorMessage(response["data"]["message"]);

            setTimeout(() => {
              setErrorVisibility(false);
            }, 5000);

            console.log("Not getting here");
          }
        })
        .catch(function (error) {
          return error;
        });
  }

  const handleContinueShipment = () => {
    setShowPhase2Shipment(true);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const createContentFields = () => {
    const td = []
    for (let index = 1; index <= contentFields; index++){
      td.push(
          <Row className="form-row mb-3">
            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Length">Description</label>
              <input
                  name={`content_description_${index}`}
                  className="form-control"
                  type="text"
                  placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Weight">Weight</label>
              <input
                  name={`content_weight_${index}`}
                  className="form-control"
                  type="text"
                  placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Quantity">Quantity</label>
              <input
                  name={`content_quantity_${index}`}
                  className="form-control"
                  type="text"
                  placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Value">Value (USD)</label>
              <input
                  name={`content_value_${index}`}
                  className="form-control"
                  type="number"
              />
            </Col>
          </Row>
      );
    }
    return td;
  }

  const handleContentFieldsIncrease = () => {
    if (contentFields < 5){
      setContentFields(contentFields + 1);
    }
  }

  const handleContentFieldsDecrease = () => {
    if (contentFields > 1){
      setContentFields(contentFields - 1);
    }
  }

  const suggestCities = (e, type) => {
    const query = e.target.value;

    if (type === "ReceiverCity"){
      setReceiverCity(e.target.value);
    }

    if (query.toString().trim() === ""){
      setShowReceiverCitySuggestions(false);
      return null;
    }

    const formValues = {}
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    axios
        .post(searchDHLCityURL, newData)
        .then(function (response){
          console.log(response);

          if (response["data"]["success"] === false) return;

          const customers = response["data"]["data"];

          console.log(customers);

          setShowReceiverCitySuggestions(true);
          setReceiverCitySuggestions(customers);
        });
  }

  const selectCity = (city, type) => {
    if (type === "ReceiverCity"){
      setReceiverCity(city)
      setShowReceiverCitySuggestions(false);
    }
  }

  const searchForCustomers = (e) => {
    const query = e.target.value;

    if (query.toString().trim() === ""){
      return null;
    }

    const formValues = {}
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    axios
        .post(searchForCustomersURL, newData)
        .then(function (response){
          console.log(response);

          if (response["data"]["success"] === false) return;

          const customers = response["data"]["data"];

          setShowSenderSuggestions(true);
          setSenderSuggestions(customers);
        });
  }

  return (
    <div className="">
      {
        isError === false ? null :
            (<ErrorMessageContainer>
              {errorMessage}
            </ErrorMessageContainer>)
      }

      {
        isSuccess=== false ? null :
            (<SuccessMessageContainer>
              {successMessage}
            </SuccessMessageContainer>)
      }

      <form ref={form} onSubmit={createShipment}>
        <section>
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>1</SteppedNumber>
            <SteppedTitle className="mx-2">Package Details</SteppedTitle>
          </div>
          <Row className="form-row mt-3">
            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Weight">Weight</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="weight" className="form-control"  type="text" />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Value">Item Value</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="declared_value" className="form-control"  type="text" />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Value">Value Currency</label>
              <select name="declared_value_currency" className="form-select">
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Length">Length</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="length" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Breadth">Breadth</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="breadth" className="form-control"  type="text" />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Height">Height</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="height" className="form-control"  type="text" />
            </Col>
          </Row>
        </section>

        <section className="mt-5">
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>2</SteppedNumber>
            <SteppedTitle className="mx-2">Sender Details</SteppedTitle>
          </div>
          <Row className="form-row">
            <Col className="form-group col-xl-4 col-md-6">
              <label htmlFor="Full Name">Full Name</label>
              <input onChange={(e) => onChangeSenderDetails(e, "senderName")} name="sender_name" value={senderName} className="form-control" type="text" />
              <div style={{width:"31%"}} className={`suggestions-field ${showSenderSuggestions ? "suggestions-field-visible" : null}`}>
                {
                  senderSuggestions.map((suggestions) => {
                    const fullName = suggestions.fullname;
                    const phoneNumber = suggestions.phone;
                    const emailAddress = suggestions.email;
                    return(
                        <div onClick={(e) => selectSender(fullName, phoneNumber, emailAddress)} key={suggestions.id} className="single-suggestion">
                          <span>{fullName}</span>
                          <div className="d-flex flex-row align-items-center">
                            <span style={{fontSize:13, marginTop:-5}}>{phoneNumber}</span>
                            <BsDot/>
                            <span style={{fontSize:13, marginTop:-5}}>{emailAddress}</span>
                          </div>
                        </div>
                    );
                  })
                }
              </div>
            </Col>

            <Col className="form-group col-xl-4 col-md-6">
              <label htmlFor="Email">Email</label>
              <input onChange={(e) => onChangeSenderDetails(e, "senderEmailAddress")} value={senderEmailAddress} name="sender_email" className="form-control" type="text" placeholder="" />
            </Col>

            <Col className="form-group col-xl-4 col-md-6">
              <label htmlFor="Phone Number">Phone Number</label>
              <input
                  onChange={(e) => onChangeSenderDetails(e, "senderPhoneNumber")} value={senderPhoneNumber}
                name="sender_phone" className="form-control"
                type="text"
                placeholder=""
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Sender City">City</label>
              <input name="sender_city" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Address">State</label>
              <input
                name="sender_address"
                className="form-control"
                type="text"
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Address">Address</label>
              <input
                  name="sender_address"
                  className="form-control"
                  type="text"
                  maxLength={45}
              />
            </Col>

            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Address">Address 2 (Optional)</label>
              <input
                  name="sender_address_2"
                  className="form-control"
                  type="text"
                  placeholder=""
                  maxLength={45}
              />
            </Col>
          </Row>
        </section>

        <section className="mt-5">
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>3</SteppedNumber>
            <SteppedTitle className="mx-2">Receiver Details</SteppedTitle>
          </div>
          <Row className="form-row">
            <Col className="form-group col-xl-4 col-md-6">
              <label htmlFor="Full Name">Full Name</label>
              <input
                name="receiver_name"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
              />
            </Col>

            <Col className="form-group col-xl-4 col-md-6">
              <label htmlFor="Email">Email</label>
              <input
                name="receiver_email"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-4 col-md-6">
              <label htmlFor="Phone Number">Phone Number</label>
              <input
                name="receiver_phone"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Address">Address</label>
              <input
                name="receiver_address"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
                maxLength={45}
              />
            </Col>

            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Address">Address 2 (Optional)</label>
              <input
                name="receiver_address_2"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
                placeholder=""
                maxLength={45}
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Receiver City">City</label>
              <input name="receiver_city" className="form-control" onChange={(e) => suggestCities(e, "ReceiverCity")} value={receiverCity} type="text" />
              <div style={{width:"46%"}} className={`suggestions-field ${showReceiverCitySuggestions ? "suggestions-field-visible" : null}`}>
                {
                  receiverCitySuggestions.map((suggestions) => {
                    return(
                        <div onClick={(e) => selectCity(suggestions.city_name, "ReceiverCity")} key={suggestions.id} className="single-suggestion">
                          <span>{suggestions.city_name}</span>
                          <span style={{fontSize:13, marginTop:-5}}>{suggestions.service_area}</span>
                        </div>
                    );
                  })
                }
              </div>
            </Col>

            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Receiver State">State</label>
              <input
                name="receiver_county"
                className="form-control"
                type="text"
              />
            </Col>
          </Row>

          {isShipmentCalculated === false && isCalculatingShipment === false ? (
              <div className="d-flex justify-content-end mt-5">
                <SubmitButton onClick={calculateDHLPrice} type="button" className="confirm-button">
                  <span className="button-text">CALCULATE COST</span>
                </SubmitButton>
              </div>
          ) : isShipmentCalculated === true ? null : (
              <div className="d-flex justify-content-end mt-5">
                <SubmitButton onClick={calculateDHLPrice} type="button" className="confirm-button">
                  <span className="spinner-border spinner-grow-sm"/>
                </SubmitButton>
              </div>
          )}

          {isShipmentCalculated === true && showPhase2Shipment === false ? (
              <div className="d-flex justify-content-end mt-5">
                <SubmitButton type="button" className="confirm-button">
                  <span onClick={handleContinueShipment} className="button-text">CONTINUE</span>
                </SubmitButton>
              </div>
          ) : null}
        </section>

        {showPhase2Shipment === false ? null :
        <div>
          <section className="mt-5">
            <div className="d-flex align-items-center mb-2">
              <SteppedNumber>4</SteppedNumber>
              <SteppedTitle className="mx-2">Carriers <span style={{fontSize:15, fontStyle:"italic"}}>(Select a carrier)</span></SteppedTitle>
            </div>
            <table style={{width:"100%"}}>
              <tbody style={{width:"100%"}}>
              {carriers.map((carrier) => {
                const selectCarrier = (selectedCarrier) => {
                  let newCarriers = [...carriers];
                  newCarriers.forEach((transporter) => {
                    transporter["selected"] = false;
                  });

                  const carrierIndex = carriers.findIndex((carrier) => carrier.carrier_id === selectedCarrier.carrier_id);

                  newCarriers[carrierIndex] = {...newCarriers[carrierIndex],
                    selected: !newCarriers[carrierIndex].selected};

                  setCarriers(newCarriers);

                  setSelectedCarrierAmount(carrier.amount);
                  setSelectedCarrierName(selectedCarrier.carrier_name);

                  if (selectedCarrier.carrier_name === "DHL + Insurance"){
                    setSelectedCarrierInsurance("Yes");
                    console.log("Yes");
                  } else {
                    setSelectedCarrierInsurance("No");
                    console.log("No");
                  }
                }

                return (
                    <tr onClick={()=> selectCarrier(carrier)} style={{cursor:"pointer"}} key={carrier.carrier_id}>
                      <td className="description" style={{width:100}}>
                        <div className="d-flex align-items-center">
                          <img className="icon" src={carrier.carrier_logo} alt="" />
                        </div>
                      </td>
                      <td className="amount">
                        <div className="d-flex flex-column">
                          <span style={{fontSize:18}}>{carrier.carrier_name}</span>
                          <span>{carrier.delivery_time}</span>
                        </div>
                      </td>
                      <td className="amount" style={{fontSize:20}}>₦{parseFloat(carrier.amount).toLocaleString("en")}</td>
                      <td className="status">
                        {carrier.selected === false ? null :
                            <div style={{borderRadius:"50%", float:"right", height:40, width:40, backgroundColor: "#008561", textAlign:"center"}}>
                              <BiIcons.BiCheck style={{fontSize:25, color: "white", marginTop:7}}/>
                            </div>
                        }
                      </td>
                    </tr>
                );
              })}
              </tbody>
            </table>
          </section>

          <section className="mt-5">
            <div className="d-flex align-items-center mb-2">
              <SteppedNumber>5</SteppedNumber>
              <SteppedTitle className="mx-2">Content Details</SteppedTitle>
            </div>
            { createContentFields() }
            <div className="d-flex justify-content-center">
              <ContentFieldsActionButton onClick={handleContentFieldsDecrease}>-</ContentFieldsActionButton>
              <ContentFieldsActionButton onClick={handleContentFieldsIncrease}>+</ContentFieldsActionButton>
            </div>
          </section>

          <section className="mt-5">
            <div className="d-flex align-items-center mb-2">
              <SteppedNumber>6</SteppedNumber>
              <SteppedTitle className="mx-2">Entire Package Details</SteppedTitle>
            </div>
            <Row className="form-row mt-3">
              <Col className="form-group col-xl-8 col-md-6">
                <label htmlFor="Description">Description</label>
                <input
                    name="description"
                    className="form-control"
                    type="text"
                    maxLength={80}
                />
              </Col>

              <Col className="form-group col-xl-4 col-md-6">
                <label htmlFor="Sender Postal">Payment Method</label>
                <select name="payment_method" className="form-select">
                  <option value="">Select Payment Method</option>
                  <option value="Cash">Cash</option>
                  <option value="Card Payment">Card Payment</option>
                  <option value="Direct Transfer">Direct Transfer</option>
                  <option value="Wallet">Wallet</option>
                </select>
              </Col>
            </Row>

            <Row className="form-row mt-3">
              <Col className="form-group col-xl-4 col-md-6">
                <label htmlFor="Weight">Choose Container Type</label>
                <select name="container_type_id" className="form-select" >
                  <option value="">Choose container type</option>
                  {containerTypes.map((container) => {
                    return(<option key={container.id} value={container.id}>{container.name}</option>);
                  })}
                </select>
              </Col>

              <Col className="form-group col-xl-4 col-md-6">
                <label htmlFor="Sender Country">DHL Pickup Location</label>
                <select name="pickup_detail_id" className="form-select" >
                  <option value="">Choose pickup location</option>
                  {pickupLocations.map((locations) => {
                    return(<option key={locations.id} value={locations.id}>{locations.address}</option>);
                  })}
                </select>
              </Col>

              <Col className="form-group col-xl-4 col-md-6">
                <label htmlFor="Sender City">DHL Pickup Date</label>
                <ReactDatePicker
                    name="shipping_date"
                    className="form-control"
                    selected={startDate}
                    dateFormat="P"
                    onChange={(date: Date) => setStartDate(date)}
                />
                {/* <input className="form-control" type="text" /> */}
              </Col>
            </Row>
          </section>

          <div className="d-flex flex-row my-5 justify-content-end">
            <SubmitButton type="submit" className="confirm-button">
              {isCreatingShipment === false ? (
                  <span className="button-text">PROCESS SHIPMENT</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
            </SubmitButton>
          </div>
        </div>
        }
      </form>
    </div>
  );
};

export default CreateInterstateDHLShipmentForm;
