import {Button, Col, Modal, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL,
    fundUserWalletURL,
    getContractDetailsUserURL,
    getInternational3PLPricingURL,
    getInterstate3PLPricingURL,
    getShipmentPricingURL,
    getShipmentsURL,
    makeContractUserURL,
    topupUserWalletURL, updateInternational3PLPricingURL,
    updateInterstate3PLPricingURL
} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {BiX} from "react-icons/bi";
import * as React from "react";
import {SessionManagement} from "../library/SessionManagement";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const International3PLPriceSettingsDialog = ({ isModalVisible, handleCloseDialog, shipmentPricing}) => {
    const form = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [premiumPricing, setPremiumPricing] = useState("");
    const [priorityPricing, setPriorityPricing] = useState("");
    const [saversPricing, setSaversPricing] = useState("");
    const [standardPricing, setStandardPricing] = useState("");
    const [economyPricing, setEconomyPricing] = useState("");

    useEffect(() => {
        axios
            .post(getInternational3PLPricingURL, UserProfileData())
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    if (response["data"]["data"] === false){

                    } else {
                        const premium = response["data"]["data"]["data"]["premium"];
                        const standard = response["data"]["data"]["data"]["standard"];
                        const savers = response["data"]["data"]["data"]["savers"];
                        const priority = response["data"]["data"]["data"]["priority"];
                        const economy = response["data"]["data"]["data"]["economy"];

                        setPremiumPricing(premium)
                        setSaversPricing(savers)
                        setPriorityPricing(priority)
                        setStandardPricing(standard)
                        setEconomyPricing(economy)
                    }
                }
            })
            .catch(function (error) {
                return error;
            });
    }, []);

    const onChangeCarrier = (type, event) => {
        if (type === "dhl"){
            setPremiumPricing(event.target.value);
        } else if (type === "ups"){
            setSaversPricing(event.target.value);
        } else if (type === "fedex"){
            setStandardPricing(event.target.value);
        } else if (type === "aramex"){
            setPriorityPricing(event.target.value);
        } else if (type === "others"){
            setEconomyPricing(event.target.value);
        }
    }

    const setInternational3PLPricing = () => {
        const formValues = {};

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        console.log(data)

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        console.log(formValues);

        axios
            .post(updateInternational3PLPricingURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(response["data"]["data"]["data"]);

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                return error;
            });
    }

  return (
          <Modal show={isModalVisible} onHide={handleCloseDialog}>
              <Modal.Header>
                  <h5 style={{marginTop:10}} className="header">SET INTERNATIONAL 3PL PRICING</h5>
                  <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
              </Modal.Header>
              <Modal.Body>
                  {
                      isError === false ? null :
                          (<ErrorMessageContainer>
                              {errorMessage}
                          </ErrorMessageContainer>)
                  }

                  {
                      isSuccess=== false ? null :
                          (<SuccessMessageContainer>
                              {successMessage}
                          </SuccessMessageContainer>)
                  }

                  <form ref={form}>
                      <div>
                          <div>
                              <Row className="mt-3">
                                  <Col>
                                      <label htmlFor="Base From">Premium (%)</label>
                                      <input value={premiumPricing} onChange={(e) => onChangeCarrier("dhl", e)} name="premium" className="form-control" type="number" />
                                  </Col>
                                  <Col>
                                      <label htmlFor="Base To">Priority (%)</label>
                                      <input value={priorityPricing} onChange={(e) => onChangeCarrier("aramex", e)} name="priority" className="form-control" type="number" />
                                  </Col>
                              </Row>

                              <Row className="mt-3">
                                  <Col>
                                      <label htmlFor="Base From">Standard (%)</label>
                                      <input value={standardPricing} onChange={(e) => onChangeCarrier("fedex", e)}  name="standard" className="form-control" type="number" />
                                  </Col>
                                  <Col>
                                      <label htmlFor="Base To">Savers (%)</label>
                                      <input value={saversPricing} onChange={(e) => onChangeCarrier("ups", e)}  name="savers" className="form-control" type="number" />
                                  </Col>
                              </Row>

                              <Row className="mt-3">
                                  <Col>
                                      <label htmlFor="Sender Postal">Economy (%)</label>
                                      <input value={economyPricing} onChange={(e) => onChangeCarrier("others", e)}  name="economy" className="form-control" type="number" />
                                  </Col>
                              </Row>
                          </div>
                      </div>
                  </form>


              </Modal.Body>
              <Modal.Footer>
                  <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                      CANCEL
                  </button>
                  <SubmitButton onClick={(e) => setInternational3PLPricing()}  type="button" className="confirm-button">
                      {isSubmitting === false ? (
                          <span className="button-text">SET INTERNATIONAL 3PL PRICING</span>
                      ) : (
                          <span className="spinner-border spinner-grow-sm"/>
                      )}
                  </SubmitButton>
              </Modal.Footer>
          </Modal>

  );
};

export default International3PLPriceSettingsDialog;
