import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
    approveShipmentURL,
    getCarriersPriceURL,
    getRedstarCitiesByStateURL,
    getRedstarCitiesURL
} from "../library/URLs";
import { useRef, useState, useEffect } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import * as React from "react";
import Select from "react-select";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const styles = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      border: "1px solid rgba(0, 0, 0, 0.15)",
      outline: isFocused && "none",
      boxShadow: "none",
      minHeight: 42,
      height: 42,
      margin: 0,
      backgroundColor: "transparent",
      ":hover": {
        outline: "none",
        border: "1px solid rgba(0, 0, 0, 0.15)",
      },
      padding: 0,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      backgroundColor: "#fff",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      border: "none",
      color: "#000",
      fontSize: "8px",
      fontWeight: 500,
      backgroundColor: "transparent",
    }),
    input: (styles) => ({
      ...styles,
      padding: 0,
      margin: 0,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0 8px",
    }),
  };

const ApproveRedstarLocalShipmentDialog = ({
    isModalVisible,
    handleCloseDialog,
    shipmentID,
    senderState,
    receiverState,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [carriers, setCarriers] = useState([]);

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [redstar, setRedstar] = useState(true);

    const [senderCities, setSenderCities] = useState([]);
    const [receiverCities, setReceiverCities] = useState([]);
    const [selectedRedstarCity, setSelectedRedstarCity] = useState({
        redstar_sender_city: "",
        redstar_receiver_city: "",
    });
    const [redstarCities, setRedstarCities] = useState([]);

    const handleRedstarSender = (val) => {

        const formvalues = {
            ...selectedRedstarCity,
            redstar_sender_city: val?.value,
            redstar_sender_city_id: redstarCities?.find(city => city?.value === val?.value)?.id
        };

        setSelectedRedstarCity({
            ...formvalues
        });
    };

    const handleRedstarReceiver = (val) => {
        console.log("af ", val);

        const formvalues = {
            ...selectedRedstarCity,
            redstar_receiver_city: val?.value,
            redstar_receiver_city_id: redstarCities?.find(city => city?.value === val?.value)?.id
        };

        setSelectedRedstarCity({
            ...formvalues
        });
    };

    const handleRedstarCity = (e) => {
        const { name, value } = e.target;

        const formvalues = {
            ...selectedRedstarCity,
            [name]: value,
        };

        if(name === "redstar_sender_city") {
            formvalues["redstar_sender_city_id"] = redstarCities?.find(city => city?.name === value)?.id
        }

        if(name === "redstar_receiver_city") {
            formvalues["redstar_receiver_city_id"] = redstarCities?.find(city => city?.name === value)?.id
        }

        setSelectedRedstarCity({
            ...formvalues
        });
    };

    const [shipmentProcessor, setShipmentProcessor] = useState("Redstar");

    const [processViaAPI, setProcessViaAPI] = useState("Yes");

    useEffect(() => {
        getRedstarCities();
        const newData = { shipment_id: shipmentID, ...UserProfileData() };
        axios
            .post(getCarriersPriceURL, newData)
            .then((response) => {
                if (response["data"]["success"]) {
                    setCarriers(response["data"]["data"]["carriers"]);
                }
            })
            .catch((error) => console.log(error));

        
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     console.log("ssg", senderState, receiverState);
    //     if (senderState) {
    //         getSenderRedstarCities();
    //     }

    //     if (receiverState) {
    //         getReceiverRedstarCities();
    //     }
    //     // eslint-disable-next-line
    // }, [senderState, receiverState]);

    const toggleShipmentTypeCheckBox = (shipmentType) => {
        if (shipmentType === "Redstar") {
            setRedstar(true);
            setShipmentProcessor("Redstar");
            setProcessViaAPI("Yes");
        } 
    };

    const getSenderRedstarCities = () => {
        const newData = {
            shipment_id: shipmentID,
            state: senderState === "Federal Capital Territory" ? "Abuja" : senderState,
            ...UserProfileData(),
        };

        axios
            .post(getRedstarCitiesByStateURL, newData)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === true) {
                    setSenderCities(response["data"]["data"]);
                } else {
                    // setErrorMessage(response["data"]["message"]);
                    // setErrorVisibility(true);
                    // setTimeout(() => {
                    //     setErrorVisibility(false);
                    // }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    const getReceiverRedstarCities = () => {
        const newData = {
            shipment_id: shipmentID,
            state: receiverState === "Federal Capital Territory" ? "Abuja" : receiverState,
            ...UserProfileData(),
        };

        axios
            .post(getRedstarCitiesByStateURL, newData)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === true) {
                    if(response["data"]["data"] === null) {
                        setReceiverCities([]);
                    } else {
                        setReceiverCities(response["data"]["data"]);
                    }
                } else {
                    // setErrorMessage(response["data"]["message"]);
                    // setErrorVisibility(true);
                    // setTimeout(() => {
                    //     setErrorVisibility(false);
                    // }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    const getRedstarCities = () => {
        const newData = {
            shipment_id: shipmentID,
            ...UserProfileData(),
        };

        axios
            .post(getRedstarCitiesURL, newData)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === true) {
                    setRedstarCities(response["data"]["data"]?.map((city) => {
                        return {
                            label: city?.name,
                            value: city?.name,
                            id: city?.id
                        }
                    }));
                } else {
                    // setErrorMessage(response["data"]["message"]);
                    // setErrorVisibility(true);
                    // setTimeout(() => {
                    //     setErrorVisibility(false);
                    // }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    const approveShipment = (waybillURL) => {
        const formValues = {};
        formValues["shipment_id"] = shipmentID;
        formValues["shipment_type"] = "Local";
        formValues["shipment_processor"] = shipmentProcessor;
        formValues["via_processor_api"] = processViaAPI;
        if (redstar) {
            formValues["redstar_sender_city"] =
                selectedRedstarCity.redstar_sender_city;
            formValues["redstar_receiver_city"] =
                selectedRedstarCity.redstar_receiver_city;
            formValues["redstar_sender_city_id"] =
                selectedRedstarCity.redstar_sender_city_id;
            formValues["redstar_receiver_city_id"] =
                selectedRedstarCity.redstar_receiver_city_id;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(approveShipmentURL, newData)
            .then(function (response) {
                setIsSubmitting(false);
                console.log(response);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment has now been approved for processing");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog("Refresh");
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 className="header">APPROVE LOCAL SHIPMENT</h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                <p>You are about to approve this shipment</p>
                <p>This means the shipment would be processed for delivery.</p>
                <p>How would you like to continue?</p>

                <div>

                    <div
                        style={{ marginTop: 10 }}
                        className="d-flex gap-1 align-items-center"
                    >
                        <input
                            name="shipment_processor"
                            checked={redstar}
                            onChange={(e) => toggleShipmentTypeCheckBox("Redstar")}
                            className="form-check-input"
                            type="radio"
                        />
                        {/* <label className="form-check-label" htmlFor="allowedShipment">
                            
                        </label> */}
                        <div className="d-flex w-100 justify-content-between">
                            <label className="form-check-label" htmlFor="allowedShipment">
                                Redstar (Express Standard)
                            </label>
                            <span className="d-flex gap-2 align-items-end">
                                
                            </span>
                        </div>
                    </div>
                    {redstar && (
                        <div className="row gap-3 my-3">
                            <div className="col-12">
                                <label htmlFor="" className="form-check-label">
                                    Sender City
                                </label>
                                <Select
                                    // className="basic-single"
                                    classNamePrefix="select"
                                    value={redstarCities?.find(city => city?.value === selectedRedstarCity.redstar_sender_city)}
                                    // isDisabled={isDisabled}
                                    onChange={handleRedstarSender}
                                    // defaultValue={selectedRedstarCity.redstar_sender_city ??"Select"}
                                    isClearable={false}
                                    isSearchable={true}
                                    styles={styles}
                                    name={"redstar_sender_city"}
                                    options={redstarCities ?? []}
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="" className="form-check-label">
                                    Receiver City
                                </label>
                                <Select
                                    // className="basic-single"
                                    classNamePrefix="select"
                                    value={redstarCities?.find(city => city?.value === selectedRedstarCity.redstar_receiver_city)}
                                    // isDisabled={isDisabled}
                                    onChange={handleRedstarReceiver}
                                    // defaultValue={selectedRedstarCity.redstar_receiver_city ??"Select"}
                                    isClearable={false}
                                    isSearchable={true}
                                    styles={styles}
                                    name={"redstar_receiver_city"}
                                    options={redstarCities ?? []}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>

                <SubmitButton
                    onClick={(e) => approveShipment("")}
                    type="button"
                    className="confirm-button"
                >
                    {isSubmitting === false ? (
                        <span className="button-text">APPROVE SHIPMENT</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ApproveRedstarLocalShipmentDialog;
