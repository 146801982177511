import "./styles/home.scss";
import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Shipments from "./pages/Shipments";
import SingleShipment from "./pages/SingleShipment";
import CreateShipment from "./pages/CreateShipment";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import {PrivateRoute, PublicRoute} from "./pages/Auth/RouteManager";
import Accounts from "./pages/Accounts";
import SingleCustomer from "./pages/SingleCustomer";
import Admins from "./pages/Admins";
import Whatsapp from "./pages/Whatsapp";
import SingleAdmin from "./pages/SingleAdmin";
import Reports from "./pages/Reports/ShipmentReports";
import ShipmentReports from "./pages/Reports/ShipmentReports";
import ReportsHome from "./pages/Reports/ReportsHome";
import Transactions from "./pages/Transactions";
import SingleTransaction from "./pages/SingleTransaction";
import Notifications from "./pages/Notifications";
import CreateAdmin from "./pages/CreateAdmin";
import CustomerReports from "./pages/Reports/CustomerReports";
import TransporterReports from "./pages/Reports/TransporterReports";
import Partners from "./pages/Partners";
import SinglePartner from "./pages/SinglePartner";
import Transporters from "./pages/Transporter/Transporters";
import CreateTransporter from "./pages/Transporter/CreateTransporter";
import Settings from "./pages/Settings";
import ResetPassword from "./pages/Auth/ResetPassword";
import CustomerWallet from "./pages/CustomerWallet";
import CustomersWallet from "./pages/CustomerWallet";
import CustomersWalletHistory from "./pages/CustomerWalletHistory";
import Stations from "./pages/Stations";
import I4nnova from "./pages/i4nnova";
import SingleTransporter from "./pages/SingleTransporter";
import Cashbacks from "./pages/Cashbacks";
import CashbackCustomers from "./pages/CashbackCustomers";

function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route element={<PublicRoute/>}>
                        <Route path="login" element={<Login/>}/>
                        <Route path="register" element={<Register/>}/>
                        <Route path="forgot-password" element={<ForgotPassword/>}/>
                        <Route path="reset-password" element={<ResetPassword/>}/>
                    </Route>
                    <Route element={<PrivateRoute />}>
                        <Route element={<I4nnova/>} path="/i4nnova" />
                        <Route element={<Dashboard/>} path="/" exact />
                        <Route element={<Whatsapp/>} path="/whatsapp" />
                        <Route element={<Accounts/>} path="/account" />
                        <Route element={<Customers/>} path="/customers" />
                        <Route element={<Stations/>} path="/stations" />
                        <Route element={<Transporters/>} path="/transporters" />
                        <Route element={<Notifications/>} path="/notifications" />
                        <Route element={<Settings/>} path="/settings" />
                        <Route path="customer">
                            <Route path=":customerID" element={<SingleCustomer/>}/>
                        </Route>
                        <Route element={<Transactions/>} path="/transactions" />
                        <Route element={<CustomersWallet/>} path="/customers-wallet" />
                        <Route path="customer-wallet-history">
                            <Route path=":customerID" element={<CustomersWalletHistory/>}/>
                        </Route>
                        <Route path="transaction">
                            <Route path=":transactionID" element={<SingleTransaction/>}/>
                        </Route>
                        <Route element={<Admins/>} path="/admins" />
                        <Route path="admin">
                            <Route path=":adminID" element={<SingleAdmin/>}/>
                        </Route>
                        <Route path="transporter">
                            <Route path=":transporterID" element={<SingleTransporter/>}/>
                            <Route element={<CreateTransporter/>} path="create-transporter" />
                            <Route element={<CreateTransporter/>} path="edit-transporter" />
                            <Route element={<CreateTransporter/>} path="reset-password" />
                        </Route>
                        <Route element={<CreateAdmin/>} path="/create-admin" />
                        <Route element={<CreateShipment/>} path="/create-shipment" />
                        <Route path="shipments">
                            <Route path=":shipmentType" element={<Shipments/>}/>
                        </Route>
                        <Route path="shipment">
                            <Route path=":shipmentID" element={<SingleShipment/>}/>
                        </Route>
                        <Route element={<Partners/>} path="/partners" />
                        <Route path="partner">
                            <Route path=":partnerID" element={<SinglePartner/>}/>
                        </Route>
                        <Route path="reports">
                            <Route index element={<ReportsHome/>}/>
                            <Route path="shipments" element={<ShipmentReports />} />
                            <Route path="customers" element={<CustomerReports />} />
                            <Route path="transporters" element={<TransporterReports />} />
                        </Route>
                        <Route path="all-cashbacks">
                            <Route index element={<Cashbacks />} />
                            <Route path="eligible-customers" element={<CashbackCustomers />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
