import "../../styles/login.scss";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {generateTransactionID} from "../../library/utilities";
import axios from "axios";
import {loginURL, registerURL} from "../../library/URLs";
import {SessionManagement} from "../../library/SessionManagement";
import {ErrorNotification} from "../../components/Notifications";

const PageHeader = styled.h4`
  font-family: Prompt,sans-serif;
  font-size: 55px;
  text-align: center;
  margin-bottom: 10px;
  color: #0b613a;
`;

const PageSubheader = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const Register = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const form = useRef(null);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  function getDevice() {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }

    return currentBrowser + " on " + operatingSystem;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();

    const data = new FormData(form.current);

    const formValues = {};

    for (let [key, value] of data.entries()) {
      formValues[key] = value;
    }
    formValues["device_id"] = "value";
    formValues["device_name"] = getDevice();
    formValues["token"] = "value";
    formValues["transaction_id"] = generateTransactionID(15);

    setIsSubmitting(true);

    axios
        .post(registerURL, formValues)
        .then(function (response) {
          console.log(response);
          setIsSubmitting(false);

          if (response["data"]["success"] === false) {
            setErrorMessage(response["data"]["message"]);
            setErrorVisibility(true);

            setTimeout(() => {
              setErrorVisibility(false);
            }, 5000);
          } else if (response["data"]["success"] === true) {
            const name = response["data"]["data"]["name"];
            const email = response["data"]["data"]["email"];
            const phone = response["data"]["data"]["phone"];
            const companyID = response["data"]["data"]["company_id"];
            const companyName = response["data"]["data"]["company_name"];
            const companyLogoURL = response["data"]["data"]["company_logo_url"];
            const companyOperatorID = response["data"]["data"]["operator_id"];
            const profileImageURL = response["data"]["data"]["profile_image_url"];
            const userID = response["data"]["data"]["user_id"];
            const sessionID = response["data"]["data"]["user_ses_id"];

            const privileges = response["data"]["privileges"];

            SessionManagement.setName(name);
            SessionManagement.setEmail(email);
            SessionManagement.setPhone(phone);
            SessionManagement.setCompanyID(companyID);
            SessionManagement.setCompanyName(companyName);
            SessionManagement.setProfileImageURL(profileImageURL);
            SessionManagement.setUserID(userID);
            SessionManagement.setSessionID(sessionID);
            SessionManagement.setCompanyLogoURL(companyLogoURL);
            SessionManagement.setCompanyOperatorID(companyOperatorID);
            SessionManagement.setAuthenticationStatus("yes");

            SessionManagement.setUserPrivileges(privileges);

            navigate('/', {replace: true});
          }
        })
        .catch(function (error) {
          return error;
        });
  };

  return (
    <div className="box-container">
      {isError === true ? (
          <ErrorNotification message={errorMessage}>
            This is a notification
          </ErrorNotification>
      ) : null}
      <PageHeader className="page-header">Create Account</PageHeader>
      <PageSubheader>
        Start managing your logistics business with ease
      </PageSubheader>
      <form onSubmit={handleSubmit}>
        <section>
          <div className="d-flex flex-row justify-content-between">
            <div className="input-container">
              <input
                  placeholder="First Name"
                  className="login-input"
                  name="first-name"
                  type="text"
              />
            </div>

            <div className="input-container">
              <input
                  placeholder="Last Name"
                  className="login-input"
                  name="last-name"
                  type="text"
              />
            </div>
          </div>

          <div className="mt-4 input-container">
            <input
              placeholder="Email Address"
              className="login-input"
              name="email"
              type="text"
            />
          </div>

          <div className="mt-4 input-container">
            <input
              placeholder="Password"
              className="login-input"
              name='password'
              type="password"
            />
          </div>
        </section>

        <div className="d-flex flex-row my-5 justify-content-end">
          <button type="submit" className="login-button">
            {isSubmitting === false ? (
                <span className="button-text">CREATE ACCOUNT</span>
            ) : (
                <span className="spinner-border spinner-grow-sm"/>
            )}
          </button>
        </div>

        <PageSubheader>
          Already have an account? <a href="/login">Login</a>
        </PageSubheader>
      </form>
    </div>
  );
};

export default Register;
