import whatsapp from "../images/whatsapp-image.png";
import "../styles/whatsapp.scss";
import "../styles/components.scss";
import "../styles/home.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {
    getConversationMessagesURL,
    getCustomerDetailsURL,
    getWhatsappChatListURL,
    sendWhatsappMessageURL,
    topupUserWalletURL
} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {HiOutlineDotsHorizontal, HiOutlineDotsVertical} from "react-icons/hi";
import styled from "styled-components";
import * as React from "react";
import * as BoxIcons from "react-icons/bi";
import moment from "moment/moment";
import {SessionManagement} from "../library/SessionManagement";
import PriceSettingsDialog from "../modals/MotorbikePriceSettingsDialog";
import SetupWhatsappModal from "../modals/SetupWhatsappDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import Pusher from "pusher-js";
import {generateTransactionID} from "../library/utilities";


const LoadingContainer = styled.div`
  height: 470px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 470px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoConversationSelectedContainer = styled.div`
  height: 568px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 70px;
`;

const Whatsapp = () => {
    const [setupWhatsappVisibility, setSetupWhatsappVisibility] = useState(false);
    const [shipmentPricing, setShipmentPricing] = useState([]);

    const form = useRef(null);

    const params = useParams();
    const customerID = params["customerID"];

    const [isRetrievingChatList, setIsRetrievingChatList] = useState(true);
    const [isSendingMessage, setIsSendingMessage] = useState(false);

    const [isLoadingConversations, setIsLoadingConversations] = useState(false);
    const [conversationSelected, setConversationSelected] = useState(false);

    const [message, setMessage] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [customerProfileImageURL, setCustomerProfileImageURL] = useState("");

    const [messagesList, setMessagesList] = useState([]);
    const [newMessage, setNewMessage] = useState({});
    const [chatList, setChatList] = useState([]);

    const[userActionMenuVisibility, setUserActionMenuVisibility] = useState(false);

    const handleCloseModal = () => {
        setSetupWhatsappVisibility(false);
    };

    const showSetupDialog = () => {
        setSetupWhatsappVisibility(!setupWhatsappVisibility);
    }

    const toggleUserActionMenu = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
    }

    const data = {
        customer_id: customerID,
    };

    const newData = { ...data, ...UserProfileData() };

    const pusher = new Pusher(
        "94b48950b89d850bb42d", {
            cluster: "eu",
        });

    useEffect(() => {
        axios
            .post(getWhatsappChatListURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setIsRetrievingChatList(false);

                if (response["data"]["success"] === false) return;

                const messagesChat = response["data"]["data"];

                messagesChat.forEach((message) => {
                    message["selected"] = false;
                })

                setChatList(messagesChat);
            });
    }, []);

    const onFieldChange = (e) => {
        setMessage(e.target.value);
    }

    const getConversationMessages = (customerNumber) => {
        const formValues = {};
        formValues['recipient_phone_number'] = customerNumber;

        const newData = { ...formValues, ...UserProfileData() };

        axios
            .post(getConversationMessagesURL, newData)
            .then(function (response) {
                setIsLoadingConversations(false);

                if (response["data"]["success"] === true) {
                    setMessagesList((response["data"]['data']).reverse());

                    setTimeout(() => {
                        const element = document.getElementById("chat-detail-body");
                        element.scrollTop = element.scrollHeight;
                    }, 1000);

                    const channelName = SessionManagement.getCompanyID() + "-" + customerNumber;
                    const channel = pusher.subscribe(channelName);

                    channel.bind("message", (data) => {
                        const myObject = JSON.parse(data.message);
                        setNewMessage(myObject);
                    });
                }
            })
            .catch(function (error) {
                return error;
            });
    }

    React.useEffect(() => {
        if (Object.keys(newMessage).length === 0){
            return;
        }

        let newMessageList = [...messagesList];

        newMessageList.push(newMessage);

        setMessagesList(newMessageList);

        if (conversationSelected === true){
            setTimeout(() => {
                const element = document.getElementById("chat-detail-body");
                element.scrollTop = element.scrollHeight;
            }, 1000);
        }
    }, [newMessage]);

    const sendMessage = (e) => {
        e.preventDefault();

        const formValues = {};
        formValues['recipient_phone_number'] = customerNumber;

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log("logging into it")

        if (message.trim() === ""){
            return;
        }

        const newValues = {
            id : generateTransactionID(),
            sender_type : "Admin",
            message : message,
            date_time : "now",
            sender_profile_image_url : SessionManagement.getProfileImageURL()
        };
        console.log(newValues);
        setNewMessage(newValues);

        setMessage("")

        axios
            .post(sendWhatsappMessageURL, newData)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                return error;
            });
    }

    return (
        <div className="home">
            <Sidebar />

            <SetupWhatsappModal
                isModalVisible={setupWhatsappVisibility}
                handleCloseDialog={handleCloseModal}
            />

            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />
                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Whatsapp</h5>
                        <span>Home</span>
                    </div>

                    <div className="container">
                        {
                            SessionManagement.getIsWhatsappSet() === "Inactive" ?
                                <div className="row mt-4">
                                    <div className="col-xl-12 col-md-12">
                                        <div className="card bg-light border-0 shadow">
                                            <div className="card-body d-flex flex-row" style={{height:600}}>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h4>You do not have Whatsapp set up yet</h4>
                                                    <span>With Parcelflow for whatsapp, you can setup your mobile number to :</span> <br/>
                                                    <ul>
                                                        <li>Send and receive whatsapp messages directly from Parcelflow (web or mobile)</li>
                                                        <li style={{marginTop:10}}>Automate shipment processing and allow customers book without human interaction</li>
                                                        <li style={{marginTop:10}}>Notify customers directly via Whatsapp</li>
                                                        <li style={{marginTop:10}}>Send receipts and invoices automatically to customers who have booked shipments</li>
                                                    </ul>
                                                    <div>
                                                        <SubmitButton onClick={showSetupDialog} className="confirm-button" style={{width:250, marginTop:25}}>
                                                            <span className="button-text">SETUP WHATSAPP</span>
                                                        </SubmitButton>
                                                        <a href="https://blog.parcelflow.io" target="_blank" className="button-text" style={{width:"100%", marginTop:25, fontSize:15, textDecoration:"none", cursor:"pointer", marginLeft:25}}>Learn More</a>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center justify-content-center" style={{width:"50%"}}>
                                                    <img src={whatsapp} style={{width:450}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 :
                                <div className="row mt-4">
                                    <div className="col-xl-4 col-md-12">
                                        <div className="card bg-light border-0 shadow">
                                            <div className="card-body shipper-container">
                                                <div>
                                                    <div className="d-flex flex-row" style={{backgroundColor:"#EBEDEF", padding:10, borderRadius:5}}>
                                                        <BoxIcons.BiSearch style={{fontSize:25}} />
                                                        <input className="search-input" placeholder="Search by name or number"/>
                                                    </div>
                                                </div>
                                                <div className="chat-list-container">
                                                    {
                                                        isRetrievingChatList === true ?
                                                            <LoadingContainer>
                                                                <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                                                            </LoadingContainer> :
                                                            chatList.length ===  0 ?
                                                                <EmptyContainer>
                                                                    <p>Oops... Sorry, you have not started any conversation</p>
                                                                </EmptyContainer> :
                                                                chatList.map((message) => {
                                                                    const selectConversation = (message) => {
                                                                        setIsLoadingConversations(true);
                                                                        setConversationSelected(true);

                                                                        setCustomerName(message.customer_name);
                                                                        setCustomerNumber(message.customer_phone_number);
                                                                        setCustomerProfileImageURL(message.customer_profile_image_url);

                                                                        getConversationMessages(message.customer_phone_number);

                                                                        let newChatList = [...chatList];
                                                                        newChatList.forEach((message) => {
                                                                            message["selected"] = false;
                                                                        });

                                                                        const messageIndex = chatList.findIndex((newMessage) => newMessage.id === message.id);

                                                                        newChatList[messageIndex] = {...newChatList[messageIndex],
                                                                            selected: !newChatList[messageIndex].selected};

                                                                        newChatList[messageIndex] = {...newChatList[messageIndex],
                                                                            status: "Read"};

                                                                        setChatList(newChatList);
                                                                    }

                                                                    const dateTime = moment(message.timestamp, "YYYY-MM-DD h:mm:ss");
                                                                    const timestamp = dateTime.fromNow();

                                                                    return (
                                                                        <div onClick={(e) => selectConversation(message)} key={message.id} className={`single-chat-list-container ${message.selected === true ? "active" : null}`}>
                                                                            <img className="user-profile-image" src={message.customer_profile_image_url} />
                                                                            <div className="d-flex flex-column info-container">
                                                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                                                    <span className="user-name">{message.customer_name}</span>
                                                                                    <span className="time">{timestamp}</span>
                                                                                </div>
                                                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                                                    <span className="message">{message.last_message}</span>
                                                                                    {message.status === "Read" ? null : <span className="counter"></span>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-8 col-md-12">
                                        <div className="card bg-light border-0 shadow">
                                            {
                                                conversationSelected === false ?
                                                    <NoConversationSelectedContainer>
                                                        You have not selected any conversation
                                                    </NoConversationSelectedContainer>:
                                                    <div className="card-body chat-detail-container">
                                                        <div  className="d-flex flex-row justify-content-start chat-detail-header" >
                                                            <img style={{width:40, height:40, borderRadius:"50%", marginRight:10}} src={customerProfileImageURL} />
                                                            <div className="chat-detail-data-container">
                                                                <span className="name">{customerName}</span>
                                                                <span className="phone">+{customerNumber}</span>
                                                            </div>
                                                            <HiOutlineDotsHorizontal onClick={toggleUserActionMenu} style={{width:20, cursor:"pointer", marginTop:-10}} className="icon"/>
                                                        </div>
                                                        {
                                                            isLoadingConversations === true ?
                                                                <LoadingContainer style={{height:423}}>
                                                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                                                                </LoadingContainer> :
                                                                <div id="chat-detail-body" className="chat-detail-body">
                                                                    {messagesList.map((message) => {
                                                                        let timestamp;
                                                                        if (message.date_time === "now"){
                                                                            timestamp = "now";
                                                                        } else {
                                                                            const dateTime = moment(message.date_time, "YYYY-MM-DD h:mm:ss");
                                                                            timestamp = dateTime.fromNow();
                                                                        }

                                                                        if (message.sender_type === "Customer"){
                                                                            return (
                                                                                <div key={message.id} style={{marginTop:20}}>
                                                                                    <div>
                                                                                        <img style={{width:30, height:30, borderRadius:"50%"}} src={message.receiver_profile_image_url} />
                                                                                        <span style={{fontSize:14, marginLeft:10}}>{customerName}</span>
                                                                                        <span style={{fontSize:10.5, marginLeft:10}}>{timestamp}</span>
                                                                                    </div>
                                                                                    <div style={{marginTop:5, backgroundColor: "#E8E8E8", width:"50%", borderRadius:"5px", padding:10}}>
                                                                                        <span style={{fontSize:14.5, color:  "#535a60"}}>{message.message}</span>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div key={message.id} style={{marginTop:20, marginRight:10}} className="d-flex flex-column align-items-end">
                                                                                    <div>
                                                                                        <img style={{width:30, height:30, borderRadius:"50%"}} src={message.sender_profile_image_url} />
                                                                                        <span style={{fontSize:14, marginLeft:10}}>{message.sender_name}</span>
                                                                                        <span style={{fontSize:10.5, marginLeft:10}}>{timestamp}</span>
                                                                                    </div>
                                                                                    <div style={{marginTop:5, backgroundColor: "#008561", width:"50%", borderRadius:"5px", padding:10}}>
                                                                                        <span style={{fontSize:14.5, color:"white"}}>{message.message}</span>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    })}
                                                                </div>

                                                        }
                                                        <form ref={form} onSubmit={sendMessage}>
                                                            <div className="d-flex flex-row" style={{borderTop: "1px solid #E5E8E8", paddingTop:15}}>
                                                                <div style={{backgroundColor:"#EBEDEF", padding:10, borderRadius:5, width:"100%", marginRight:30}}>
                                                                    <input onChange={onFieldChange} value={message} className="search-input" name="message" placeholder="Enter message"/>
                                                                </div>
                                                                <SubmitButton type="submit" className="confirm-button">
                                                                    {isSendingMessage === false ? (
                                                                        <span className="button-text">SEND</span>
                                                                    ) : (
                                                                        <span className="spinner-border spinner-grow-sm"/>
                                                                    )}
                                                                </SubmitButton>
                                                            </div>
                                                        </form>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Whatsapp;
