import {Button, Col, Modal, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL,
    fundUserWalletURL,
    getContractDetailsUserURL,
    makeContractUserURL, topupUserWalletURL
} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {BiX} from "react-icons/bi";
import * as React from "react";
import {SessionManagement} from "../library/SessionManagement";
import ReactDatePicker from "react-datepicker";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const SearchShipmentsDialog = ({ isModalVisible, handleCloseDialog, customerID, phoneNumber, customerName }) => {
    const [localShipment, setLocalShipment] = useState(false);
    const [interstateShipment, setInterstateShipment] = useState(false);
    const [internationalShipment, setInternationalShipment] = useState(false);

    const [paidShipment, setPaidShipment] = useState(false);
    const [unpaidShipment, setUnpaidShipment] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const toggleShipmentType = (e, type) => {
        if (type === "localShipment"){
            setLocalShipment(!localShipment);
        } else if (type === "interstateShipment"){
            setInterstateShipment(!interstateShipment);
        } else if (type === "internationalShipment"){
            setInternationalShipment(!internationalShipment);
        }
    }

    const toggleShipmentPaidStatus = (e, type) => {
        if (type === "paidShipment"){
            setPaidShipment(!paidShipment);
        } else if (type === "unpaidShipment"){
            setUnpaidShipment(!unpaidShipment);
        }
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">SEARCH SHIPMENTS</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <div>
              <Row className="mt-3">
                  <Col>
                      <label htmlFor="Receiver Name">Receiver Name</label>
                      <input name="receiver_name" className="form-control" type="text" />
                  </Col>
                  <Col>
                      <label htmlFor="Receiver Address">Receiver Address</label>
                      <input name="receiver_address" className="form-control" type="text"  />
                  </Col>
              </Row>

              <Row className="mt-3">
                  <span>Shipment Type</span>
                  <Col>
                      <input name="intracity_truck" checked={localShipment} onChange={(e) => toggleShipmentType(e, "localShipment")}
                             className="form-check-input" type="checkbox" value="1"  />
                      <label className="form-check-label" htmlFor="localShipment">
                          Local
                      </label>
                  </Col>
                  <Col>
                      <input name="interstate_shipment" checked={interstateShipment} onChange={(e) => toggleShipmentType(e, "interstateShipment")}
                             className="form-check-input" type="checkbox" value="1"  />
                      <label className="form-check-label" htmlFor="interstateShipment">
                          Interstate
                      </label>
                  </Col>
                  <Col>
                      <input name="international_shipment" checked={internationalShipment} onChange={(e) => toggleShipmentType(e, "internationalShipment")}
                             className="form-check-input" type="checkbox" value="1"  />
                      <label className="form-check-label" htmlFor="internationalShipment">
                          International
                      </label>
                  </Col>
              </Row>

              <Row className="mt-3">
                  <Col>
                      <label htmlFor="Receiver Name">Shipment ID/Number</label>
                      <input name="shipment_number" className="form-control" type="text" />
                  </Col>
                  <Col>
                      <label htmlFor="Receiver Address">DHL Tracking Number</label>
                      <input name="dhl_tracking_number" className="form-control" type="text"  />
                  </Col>
              </Row>

              <Row className="mt-3">
                  <span>Payment Status</span>
                  <Col>
                      <input name="paid_shipment" checked={paidShipment} onChange={(e) => toggleShipmentPaidStatus(e, "paidShipment")}
                             className="form-check-input" type="checkbox" value="1"  />
                      <label className="form-check-label" htmlFor="paidShipment">
                          Paid
                      </label>
                  </Col>
                  <Col>
                      <input name="unpaid_shipment" checked={unpaidShipment} onChange={(e) => toggleShipmentPaidStatus(e, "unpaidShipment")}
                             className="form-check-input" type="checkbox" value="1"  />
                      <label className="form-check-label" htmlFor="unpaidShipment">
                          Unpaid
                      </label>
                  </Col>
              </Row>

              <Row className="mt-3">
                  <Col>
                      <label htmlFor="Pickup Location">Start Date</label>
                      <ReactDatePicker
                          name="start_date"
                          className="form-control"
                          selected={startDate}
                          dateFormat="P"
                          onChange={(date: Date) => setStartDate(date)}
                      />
                  </Col>
                  <Col>
                      <label htmlFor="Dropoff Location">End Date</label>
                      <ReactDatePicker
                          name="end_date"
                          className="form-control"
                          selected={endDate}
                          dateFormat="P"
                          onChange={(date: Date) => setEndDate(date)}
                      />
                  </Col>
              </Row>
          </div>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">SEARCH SHIPMENTS</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchShipmentsDialog;
