import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useState, useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {UserProfileData} from "../library/constants";
import {getShipmentsURL, searchForCustomersURL, searchForShipmentsURL} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import {SessionManagement} from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
import SearchShipmentsDialog from "../modals/SearchShipmentsDialog";
import {BsDot} from "react-icons/bs";

function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
}

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Shipments = () => {
    const form = useRef(null);
    const navigator = useNavigate();
    const params = useParams();

    const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
    const [chosenDate, setChosenDate] = useState("");

    const shipmentType = titleCase(params["shipmentType"].replace("-", " "));

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffsetDate, setCurrentOffsetDate] = useState("");
    const [currentOffsetID, setCurrentOffsetID] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);

    const [isSearchShipmentDialogVisible, setIsSearchShipmentDialogVisible] = useState(false);
    const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] = useState(false);

    const [resultContainerVisibility, setResultContainerVisibility] = useState(false);
    const [customerQueryResults, setCustomerQueryResults] = useState([]);
    const [isOptionSelected, setIsOptionSelected] = useState("");

    const [shipments, setShipments] = useState([]);

    const [isQuerySearching, setIsQuerySearching] = useState(false);

    const data = {
        limit: 20,
        offset_date: currentOffsetDate,
        offset_id: currentOffsetID,
        status: shipmentType === "All Shipments" ? "All" : shipmentType,
        payment_status: chosenPaymentStatus,
        date: chosenDate
    };

    const newData = {...data, ...UserProfileData()};
    console.log(newData);

    useEffect(() => {
        axios
            .post(getShipmentsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (isLoading === false && isRetrievingMore === false) {
                    console.log("Should not retrieve");
                    return;
                }
                console.log(response["data"]);

                setIsRetrievingMore(false)
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newShipments = response["data"]["data"];

                if (newShipments.length > 0) {
                    const offsetID = newShipments[newShipments.length - 1].id;
                    const offsetDate = newShipments[newShipments.length - 1].date_time;

                    setCurrentOffsetID(offsetID);
                    setCurrentOffsetDate(offsetDate);
                }

                if (currentOffsetID === 0) {
                    setShipments(newShipments);
                } else {
                    setShipments([...shipments, ...newShipments]);
                }
            });
    }, [isRetrievingMore, isOptionSelected]);

    const handleClick = (shipmentID) => {
        navigator("/shipment/" + shipmentID);
    };

    const setScroll = () => {
        console.log("poa", window.innerHeight + window.scrollY);
        console.log("oolw", document.body.offsetHeight);
        if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight-200) && isRetrievingMore === false) {
            setIsRetrievingMore(true);
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const exportToCSV = () => {
        if (shipments.length === 0)
            return;

        let csvContent = "data:text/csv;charset=utf-8,";

        csvContent += "Shipment ID,Shipment Type,Date,Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Pickup Transporter, Dropoff Transporter,Payment Method,Payment Status,Shipment Status,Weight,Description,Item Value,Approved By,Tracking Number" + "\r\n";

        shipments.forEach(function (shipment) {
            let id = shipment.id;

            let senderDetails = shipment.shipment_type + "," + shipment.date_time + "," + shipment.sender_name + "," + shipment.sender_phone_no
                + "," + shipment.pick_up_location.replaceAll(",", "");
            let receiverDetails = shipment.recipient_name + "," + shipment.recipient_phone_no
                + "," + shipment.drop_off_location.replaceAll(",", "");
            let packageDetails = shipment.total_amount.replaceAll(",", "") + "," + shipment.pick_up_transporter + "," + shipment.drop_off_transporter + ","
                + shipment.payment_method + "," + shipment.paid_status + "," + shipment.status + "," + shipment.weight + "," + shipment.item_name.replaceAll(",", "")
                + "," + shipment.item_value_currency + " " + shipment.item_value + "," + shipment.approved_by_name + "," + shipment.shipment_tracking_number;

            const row = id + "," + senderDetails + "," + receiverDetails + "," + packageDetails;

            csvContent += row + "\r\n";
        });

        const encodedURI = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedURI)
        link.setAttribute("download", "shipments1.csv")
        document.body.appendChild(link)

        link.click();
    }

    const onChangePaymentStatus = event => {
        setChosenPaymentStatus(event.target.value)
    }

    const toggleCustomDateRangeDialog = () => {
        setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
    }

    const onChangeShipmentDate = event => {
        if (event.target.value === "custom") {
            toggleCustomDateRangeDialog();
        } else {
            setChosenDate(event.target.value);
        }
    }

    const getReports = () => {
        if (chosenPaymentStatus !== "" && chosenDate !== "") {
            setIsLoading(true);
            setCurrentOffsetID(0);
            setCurrentOffsetDate("");
            setIsOptionSelected(chosenPaymentStatus + chosenDate);
        }
    }

    const handleCloseSearchShipmentsDialog = () => {
        setIsSearchShipmentDialogVisible(false);
    }

    const openSearchShipmentsDialog = () => {
        setIsSearchShipmentDialogVisible(true)
    }

    const searchForCustomers = (e) => {
        e.preventDefault();
    }

    const handleQueryChange = () => {
        const formData = new FormData(form.current);
        const query = formData.get("query");

        if (query.toString().trim() === "") {
            setIsQuerySearching(false);
            setResultContainerVisibility(false);

            return null;
        }

        const formValues = {}
        formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = {...formValues, ...UserProfileData()};

        setIsQuerySearching(true);
        setResultContainerVisibility(true);

        axios
            .post(searchForShipmentsURL, newData)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === false) return;

                const customers = response["data"]["data"];

                setCustomerQueryResults(customers);
            });
    }

    return (
        <div className="home">
            <Sidebar/>
            <div className="main">
                <Navbar/>
                <div className="page pb-5">
                    <SubscriptionInactive/>

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>{shipmentType}</h5>
                        <span>Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                        <SearchShipmentsDialog
                            isModalVisible={isSearchShipmentDialogVisible}
                            handleCloseDialog={handleCloseSearchShipmentsDialog}
                        />

                        <CustomDateRangeDialog
                            isModalVisible={isCustomDateRangeDialogVisible}
                            handleCloseDialog={(e) => {
                                if (typeof e === "string") {
                                    console.log("The chosen date is " + e.toString());
                                    setChosenDate(e);
                                }
                                toggleCustomDateRangeDialog("close");
                            }}
                        />

                        <div className="card bg-light border-0 shadow p-4 min-vh-100">

                            <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center" style={{width: "70%"}}>
                                    <form className="query-result-container" style={{width: "50%"}} ref={form}
                                          onSubmit={searchForCustomers}>
                                        <input onChange={handleQueryChange} name="query" className="form-control"
                                               type="text"
                                               placeholder="Search using receiver's name, dropoff location or shipment number"/>
                                        <div
                                            className={`query-result ${resultContainerVisibility === true ? "result-visible" : null}`}>
                                            {setIsQuerySearching === true ? null :
                                                customerQueryResults.map((customer) => {
                                                    return (
                                                        <div key={customer.id} className="d-flex flex-column"
                                                             onClick={() => handleClick(customer.id)}>
                                                            <span className="d-flex align-items-center">
                                                                {/*<span style={{fontSize: 13}}>{customer.sender_name}</span>*/}
                                                                {/*<BsDot/>*/}
                                                                <span style={{fontSize: 13}}>{customer.recipient_name}</span>
                                                            </span>
                                                            <span style={{fontSize: 15}}>{customer.drop_off_location}</span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </form>

                                    <span style={{marginLeft: 20, color: "#008561", cursor: "pointer"}}
                                          onClick={openSearchShipmentsDialog}>Advanced Search</span>
                                    {/*<select onChange={onChangePaymentStatus} style={{width:230, marginRight:10}} name="pickup_detail_id" className="form-select" >*/}
                                    {/*  <option value="">Filter By</option>*/}
                                    {/*  <option value="Paid">Paid Shipments</option>*/}
                                    {/*  <option value="Unpaid">Unpaid Shipments</option>*/}
                                    {/*</select>*/}

                                    {/*<select onChange={onChangeShipmentDate} style={{width:200, marginRight:10}} name="pickup_detail_id" className="form-select" >*/}
                                    {/*  <option value="">Choose date</option>*/}
                                    {/*  <option value="today">Today</option>*/}
                                    {/*  <option value="yesterday">Yesterday</option>*/}
                                    {/*  <option value="this week">This Week</option>*/}
                                    {/*  <option value="this month">This Month</option>*/}
                                    {/*  <option value="custom">Custom Date</option>*/}
                                    {/*</select>*/}

                                    {/*<button onClick={getReports} className="confirm-button">*/}
                                    {/*  <BiIcons.BiSearch style={{fontSize:20}}/>*/}
                                    {/*</button>*/}
                                </div>

                                <input className="confirm-button" type="button" onClick={exportToCSV}
                                       value="EXPORT TO CSV"/>
                            </div>

                            <table className="shipments-table">
                                <thead>
                                <tr>
                                    <th scope="col">Sender</th>
                                    <th scope="col">Dropoff Location</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Number</th>
                                    <th scope="col">Date</th>
                                </tr>
                                </thead>
                                {isLoading === true ? null : (
                                    <tbody>
                                    {shipments.map((shipment) => {
                                        const dateTime = moment(shipment.date_time);
                                        const timestamp = dateTime.format('ddd MMM Do, h:mm A');

                                        const status = shipment.status !== "" ? shipment.status : "Pending Delivery";

                                        return (
                                            <tr key={shipment.id} onClick={() => handleClick(shipment.id)}>
                                                <td>{shipment.sender_name}</td>
                                                <td>{shipment.drop_off_location}</td>
                                                <td>
                                                    ₦
                                                    {parseFloat(shipment.total_amount).toLocaleString(
                                                        "en"
                                                    )}
                                                </td>
                                                <td>{shipment.shipment_no}</td>
                                                <td>{timestamp}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                )}
                            </table>
                            {isRetrievingMore === false ? null :
                                <div style={{textAlign: "center", marginTop: 40}}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/> Retrieving More..
                                </div>
                            }

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shipments;
