import * as BoxIcons from "react-icons/bi";
import * as React from "react";
import {Col, Row} from "react-bootstrap";
import FundWalletDialog from "../../../modals/FundWalletDialog";
import {useEffect, useState} from "react";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import axios from "axios";
import {getCurrenciesRateUrl, getShipmentPricingURL, updateCurrencyRateUrl} from "../../../library/URLs";
import {UserProfileData} from "../../../library/constants";
import Interstate3PLPriceSettingsDialog from "../../../modals/Interstate3PLPriceSettingsDialog";
import International3PLPriceSettingsDialog from "../../../modals/International3PLPriceSettingsDialog";
import currencies from '../../../data/currencies.json';
import styled from "styled-components";
import { BsPencil } from "react-icons/bs";
import UpdateCurrencyDialog from "../../../modals/UpdateCurrencyDialog";
const SubmitButton = styled.button`
  width: 250px;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 30px;
  min-width: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee2463;
  cursor: pointer;

  svg {
    font-size: 14px;
  }
`;

const CurrencyRates = () => {
    const [currencyRates, setCurrencyRates] = useState([]);
    const [selectedRate, setSelectedRate] = useState(null);


    const fetchCurrenciesRate = async () => {
        axios
            .post(getCurrenciesRateUrl, UserProfileData())
            .then(function (response) {
                if (response["data"]["success"] === true) {
                    console.log(response);
                    setCurrencyRates(response["data"]["data"]);
                }
            })
            .catch(function (error) {
                return error;
            });
    }

    useEffect(() => {
        fetchCurrenciesRate();
    }, [])

    return(
        <>
            <UpdateCurrencyDialog 
                isModalVisible={selectedRate !== null}
                handleCloseDialog={() => setSelectedRate(null)}
                currencyRate={selectedRate}
                fetchCurrencies={fetchCurrenciesRate}
            />
            <p style={{fontSize:22, marginTop:30, marginBottom:0, fontWeight:"bold"}}>Currency Rates Settings</p>
            <span>Set Naira Rates for foreign currencies.</span>
            <div className="row mt-4">
            <form>
                <div className="row">
                    <div className="col-xl-8 col-md-6 col-sm-12 col">
                        {
                            currencyRates?.map((curr) => (
                                <Row key={curr?.id} className="mb-3" >
                                    <Col className="form-group col-xl-6 col-md-6">
                                        <label htmlFor="Full Name">Currency</label>
                                        <select name="currency" value={curr?.currency} className="form-control" id="">
                                            <option value="" hidden>Select Currency</option>
                                            {
                                                currencies.map((curr) => (
                                                    <option key={curr.cc} value={curr.cc}>
                                                        {curr.name}-{curr.symbol}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </Col>

                                    <Col className="form-group col-xl-6 col-md-6">
                                        <label htmlFor="Value">Rate Value</label>
                                        <div className="d-flex align-items-center gap-2" >
                                            <input name="rate_value" value={curr?.rate_value} className="form-control" type="text" placeholder="" />
                                            <SteppedNumber onClick={()=> {
                                                setSelectedRate(curr);
                                            }} >
                                                <BsPencil />
                                            </SteppedNumber>
                                        </div>
                                    </Col>
                                </Row>
                            ))
                        }

                    </div>

                    <div className="d-flex flex-row my-5 justify-content-start">
                        <SubmitButton type="button" className="confirm-button" onClick={()=> {
                                                setSelectedRate({
                                                    currency: "",
                                                    rate_value: ""
                                                });
                                            }}>
                            {/* {isUpdating === false ? (
                                <span className="button-text">UPDATE PROFILE</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )} */}
                            Add Currency
                        </SubmitButton>
                    </div>
                </div>
            </form>
            </div>
        </>
    )
}

export default CurrencyRates;