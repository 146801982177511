import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { approveShipmentURL, makeRegularUserURL, uploadImageURL, uploadWaybillURL, getCarriersPriceURL } from "../library/URLs";
import { useRef, useState, useEffect } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const ApproveInternationalShipmentDialog = ({ isModalVisible, handleCloseDialog, shipmentID, manualApprove }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [carriers, setCarriers] = useState([]);

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [trackingNumber, setTrackingNumber] = useState("");
    const [waybillUrl, setWaybillURL] = useState("");
    const [waybillFile, setWaybillFile] = useState();
    const [waybillLoading, setWaybillLoading] = useState(false);

    const [fedexEconomy, setFedexEconomy] = useState(false);
    const [fedexPriority, setFedexPriority] = useState(false);
    const [fedexExpress, setFedexExpress] = useState(false);
    const [dhl, setDHL] = useState(false);
    const [aramex, setAramex] = useState(false);
    const [ups, setUPS] = useState(false);
    const [anka, setAnka] = useState(false);

    const [shipmentProcessor, setShipmentProcessor] = useState("");

    const [processViaAPI, setProcessViaAPI] = useState("Yes");

    useEffect(() => {
        const newData = { shipment_id: shipmentID, ...UserProfileData() };
        axios
        .post(getCarriersPriceURL, newData)
        .then((response) => {
            if (response["data"]["success"]) {
                setCarriers(response["data"]["data"]["carriers"]);
            }
        })
        .catch(error => console.log(error));
        // eslint-disable-next-line
    }, []);

    const toggleShipmentTypeCheckBox = (shipmentType) => {
        if (shipmentType === "fedexEconomy") {
            setFedexEconomy(true);
            setFedexPriority(false);
            setFedexExpress(false);
            setDHL(false);
            setUPS(false);
            setAnka(false);
            setAramex(false);
            setShipmentProcessor("Fedex-Economy");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "fedexPriority") {
            setFedexEconomy(false);
            setFedexPriority(true);
            setDHL(false);
            setUPS(false);
            setAramex(false);
            setAnka(false);
            setFedexExpress(false);
            setShipmentProcessor("Fedex-Priority");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "dhl") {
            setFedexEconomy(false);
            setFedexPriority(false);
            setDHL(true);
            setUPS(false);
            setAramex(false);
            setAnka(false);
            setFedexExpress(false);
            setShipmentProcessor("DHL");
            setProcessViaAPI("Yes")
        } else if (shipmentType === "ups") {
            setFedexEconomy(false);
            setFedexPriority(false);
            setDHL(false);
            setUPS(true);
            setAramex(false);
            setAnka(false);
            setFedexExpress(false);
            setShipmentProcessor("UPS");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "aramex") {
            setFedexEconomy(false);
            setFedexPriority(false);
            setDHL(false);
            setUPS(false);
            setFedexExpress(false);
            setAramex(true);
            setAnka(false);
            setShipmentProcessor("Aramex");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "fedexPriorityExpress") {
            setFedexEconomy(false);
            setFedexExpress(true);
            setFedexPriority(false);
            setDHL(false);
            setUPS(false);
            setAramex(false);
            setAnka(false);
            setShipmentProcessor("Fedex-Priority-Express");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "anka") {
            setFedexEconomy(false);
            setFedexExpress(false);
            setFedexPriority(false);
            setDHL(false);
            setUPS(false);
            setAramex(false);
            setAnka(true);
            setShipmentProcessor("Anka");
            setProcessViaAPI("Yes");
        }
    }

    const uploadWaybillDocument = (e) => {
        // const fileInput = document.querySelector('#waybill-document');
        const {files} = e.target;

        const formValues = {};
        formValues["image"] = files[0];

        setWaybillLoading(true);

        axios
            .post(uploadWaybillURL, formValues, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                const waybillURL = response["data"];

                setWaybillURL(waybillURL);
                setWaybillLoading(false);
            })
            .catch(error => console.log(error));
    }

    const approveShipment = (waybillURL) => {
        if(waybillLoading) {
            setErrorMessage("Waybill document is uploading!");
            setErrorVisibility(true);
            window.scrollTo(0, 0);

            setTimeout(() => {
                setErrorVisibility(false);
            }, 3000);
            return;
        }
        
        if(manualApprove) {
            if(trackingNumber === "" || waybillUrl === "") {
                setErrorMessage("Ensure to provide tracking number and waybill when approving manually!");
                setErrorVisibility(true);
                window.scrollTo(0, 0);
    
                setTimeout(() => {
                    setErrorVisibility(false);
                }, 5000);
                return;
            } 
        }

        const formValues = {};
        formValues['shipment_id'] = shipmentID;
        formValues['shipment_type'] = "International";
        formValues['shipment_processor'] = shipmentProcessor;
        formValues['via_processor_api'] = manualApprove ? "No" : "Yes";
        
        if(manualApprove) {
            formValues['tracking_number'] = trackingNumber;
            formValues['waybill_url'] = waybillUrl;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(approveShipmentURL, newData)
            .then(function (response) {
                setIsSubmitting(false);
                console.log(response);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment has now been approved for processing");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog("Refresh");
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);
                    window.scrollTo(0, 0);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    const onTrackingNumberChanged = (e) => {
        setTrackingNumber(e.target.value)
    };

    // Function to find carrier by name 
    function findCarrierByName(name) {
        return carriers.find(company => company.name === name);
    }

    // Function to get percentage profit or loss
    function getPercentageDifference(costPriceStr, marginValueStr) {
        // Convert strings to float values
        const costPrice = parseFloat(costPriceStr.replace(/,/g, ''));
        const marginValue = parseFloat(marginValueStr.replace(/,/g, ''));

        let absoluteMargin = Math.abs(marginValue);

        let percentageDiff = (absoluteMargin / costPrice) * 100;
        return Math.round(percentageDiff);
    }

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 className="header">APPROVE INTERNATIONAL SHIPMENT</h5>
                <BiX style={{ fontSize: 25, cursor: "pointer" }} onClick={handleCloseDialog} />
            </Modal.Header>
            <Modal.Body>
                {
                    isError === false ? null :
                        (<ErrorMessageContainer>
                            {errorMessage}
                        </ErrorMessageContainer>)
                }

                {
                    isSuccess === false ? null :
                        (<SuccessMessageContainer>
                            {successMessage}
                        </SuccessMessageContainer>)
                }

                <p>You are about to approve this shipment</p>
                <p>This means the shipment would be processed for delivery.</p>
                <p>How would you like to continue?</p>

                <div>
                    <div>
                        <div className="d-flex gap-1 align-items-center" >
                            <input
                                name="shipment_processor"
                                checked={dhl}
                                onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                                className="form-check-input" type="radio"
                            />
                            <div className="d-flex w-100 justify-content-between" >
                                <label className="form-check-label" htmlFor="allowedShipment">
                                    DHL Express Premium
                                </label>
                                <span className="d-flex gap-2 align-items-end" >
                                    <span className="percentAmt" >
                                        {findCarrierByName("DHL") ?
                                            <span>&#8358;{findCarrierByName("DHL")?.cost_price}</span>
                                            : ""}
                                    </span>
                                    {
                                        findCarrierByName("DHL") ?
                                            findCarrierByName("DHL")?.margin_type === "Profit" ?
                                                <span className="percentText percentProfit" >
                                                    <BsArrowUp />
                                                    +{
                                                        getPercentageDifference(
                                                            findCarrierByName("DHL")?.cost_price,
                                                            findCarrierByName("DHL")?.margin_value
                                                        )
                                                    }%
                                                </span> :
                                                <span className="percentText percentLoss" >
                                                    <BsArrowDown />
                                                    -{
                                                        getPercentageDifference(
                                                            findCarrierByName("DHL")?.cost_price,
                                                            findCarrierByName("DHL")?.margin_value
                                                        )
                                                    }%
                                                </span> : null
                                    }
                                </span>
                            </div>
                        </div>
                        
                    </div>

                    <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center">
                        <input name="shipment_processor" checked={ups} onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                            className="form-check-input" type="radio" />
                        <div className="d-flex w-100 justify-content-between" >
                            <label className="form-check-label" htmlFor="allowedShipment">
                                UPS Express Saver
                            </label>
                            <span className="d-flex gap-2 align-items-end" >
                                <span className="percentAmt" >
                                    {findCarrierByName("UPS") ?
                                        <span>&#8358;{findCarrierByName("UPS")?.cost_price}</span>
                                        : ""}
                                </span>
                                {
                                    findCarrierByName("UPS") ?
                                        findCarrierByName("UPS")?.margin_type === "Profit" ?
                                            <span className="percentText percentProfit" >
                                                <BsArrowUp />
                                                +{
                                                    getPercentageDifference(
                                                        findCarrierByName("UPS")?.cost_price,
                                                        findCarrierByName("UPS")?.margin_value
                                                    )
                                                }%
                                            </span> :
                                            <span className="percentText percentLoss" >
                                                <BsArrowDown />
                                                -{
                                                    getPercentageDifference(
                                                        findCarrierByName("UPS")?.cost_price,
                                                        findCarrierByName("UPS")?.margin_value
                                                    )
                                                }%
                                            </span> : null
                                }
                            </span>
                        </div>
                    </div>

                    <div
                        style={{ marginTop: 10 }}
                        className="d-flex gap-1 align-items-center"
                    >
                        <input
                            name="shipment_processor"
                            // checked={ups}
                            disabled
                            className="form-check-input"
                            type="radio"
                        />
                        <div className="d-flex w-100 justify-content-between gap-5">
                            <label
                                className="form-check-label"
                                htmlFor="allowedShipment"
                            >
                                UPS Express Plus
                            </label>
                        </div>
                    </div>

                    <div
                        style={{ marginTop: 10 }}
                        className="d-flex gap-1 align-items-center"
                    >
                        <input
                            name="shipment_processor"
                            // checked={ups}
                            disabled
                            className="form-check-input"
                            type="radio"
                        />
                        <div className="d-flex w-100 justify-content-between gap-5">
                            <label
                                className="form-check-label"
                                htmlFor="allowedShipment"
                            >
                                UPS Express 
                            </label>
                        </div>
                    </div>

                    <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center" >
                        <input name="shipment_processor" checked={fedexExpress} onChange={(e) => toggleShipmentTypeCheckBox("fedexPriorityExpress")}
                            className="form-check-input" type="radio" />
                        <div className="d-flex w-100 justify-content-between" >
                            <label className="form-check-label" htmlFor="allowedShipment">
                                FedEx Priority Express
                            </label>
                            <span className="d-flex gap-2 align-items-end" >
                                <span className="percentAmt" >
                                    {findCarrierByName("FedEx Priority Express") ?
                                        <span>&#8358;{findCarrierByName("FedEx Priority Express")?.cost_price}</span>
                                        : ""}
                                </span>
                                {
                                    findCarrierByName("FedEx Priority Express") ?
                                        findCarrierByName("FedEx Priority Express")?.margin_type === "Profit" ?
                                            <span className="percentText percentProfit" >
                                                <BsArrowUp />
                                                +{
                                                    getPercentageDifference(
                                                        findCarrierByName("FedEx Priority Express")?.cost_price,
                                                        findCarrierByName("FedEx Priority Express")?.margin_value
                                                    )
                                                }%
                                            </span> :
                                            <span className="percentText percentLoss" >
                                                <BsArrowDown />
                                                -{
                                                    getPercentageDifference(
                                                        findCarrierByName("FedEx Priority Express")?.cost_price,
                                                        findCarrierByName("FedEx Priority Express")?.margin_value
                                                    )
                                                }%
                                            </span> : null
                                }
                            </span>
                        </div>
                    </div>

                    <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center" >
                        <input name="shipment_processor" checked={fedexPriority} onChange={(e) => toggleShipmentTypeCheckBox("fedexPriority")}
                            className="form-check-input" type="radio" />
                        <div className="d-flex w-100 justify-content-between" >
                            <label className="form-check-label" htmlFor="allowedShipment">
                                Fedex Priority
                            </label>
                            <span className="d-flex gap-2 align-items-end" >
                                <span className="percentAmt" >
                                    {findCarrierByName("FedEx Priority") ?
                                        <span>&#8358;{findCarrierByName("FedEx Priority")?.cost_price}</span>
                                        : ""}
                                </span>
                                {
                                    findCarrierByName("FedEx Priority") ?
                                        findCarrierByName("FedEx Priority")?.margin_type === "Profit" ?
                                            <span className="percentText percentProfit" >
                                                <BsArrowUp />
                                                +{
                                                    getPercentageDifference(
                                                        findCarrierByName("FedEx Priority")?.cost_price,
                                                        findCarrierByName("FedEx Priority")?.margin_value
                                                    )
                                                }%
                                            </span> :
                                            <span className="percentText percentLoss" >
                                                <BsArrowDown />
                                                -{
                                                    getPercentageDifference(
                                                        findCarrierByName("FedEx Priority")?.cost_price,
                                                        findCarrierByName("FedEx Priority")?.margin_value
                                                    )
                                                }%
                                            </span> : null
                                }
                            </span>
                        </div>
                    </div>

                    <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center">
                        <input name="shipment_processor" checked={fedexEconomy} onChange={(e) => toggleShipmentTypeCheckBox("fedexEconomy")}
                            className="form-check-input" type="radio" />
                        {/* <label className="form-check-label" htmlFor="allowedShipment">
                            
                        </label> */}
                        <div className="d-flex w-100 justify-content-between" >
                            <label className="form-check-label" htmlFor="allowedShipment">
                                Fedex Economy
                            </label>
                            <span className="d-flex gap-2 align-items-end" >
                                <span className="percentAmt" >
                                    {findCarrierByName("FedEx Economy") ?
                                        <span>&#8358;{findCarrierByName("FedEx Economy")?.cost_price}</span>
                                        : ""}
                                </span>
                                {
                                    findCarrierByName("FedEx Economy") ?
                                        findCarrierByName("FedEx Economy")?.margin_type === "Profit" ?
                                            <span className="percentText percentProfit" >
                                                <BsArrowUp />
                                                +{
                                                    getPercentageDifference(
                                                        findCarrierByName("FedEx Economy")?.cost_price,
                                                        findCarrierByName("FedEx Economy")?.margin_value
                                                    )
                                                }%
                                            </span> :
                                            <span className="percentText percentLoss" >
                                                <BsArrowDown />
                                                -{
                                                    getPercentageDifference(
                                                        findCarrierByName("FedEx Economy")?.cost_price,
                                                        findCarrierByName("FedEx Economy")?.margin_value
                                                    )
                                                }%
                                            </span> : null
                                }
                            </span>
                        </div>
                    </div>

                    <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center">
                        <input name="shipment_processor" checked={aramex} onChange={(e) => toggleShipmentTypeCheckBox("aramex")}
                            className="form-check-input" type="radio" />
                        {/* <label className="form-check-label" htmlFor="allowedShipment">
                            
                        </label> */}
                        <div className="d-flex w-100 justify-content-between" >
                            <label className="form-check-label" htmlFor="allowedShipment">
                                Aramex
                            </label>
                            <span className="d-flex gap-2 align-items-end" >
                                <span className="percentAmt" >
                                    {findCarrierByName("Aramex") ?
                                        <span>&#8358;{findCarrierByName("Aramex")?.cost_price}</span>
                                        : ""}
                                </span>
                                {
                                    findCarrierByName("Aramex") ?
                                        findCarrierByName("Aramex")?.margin_type === "Profit" ?
                                            <span className="percentText percentProfit" >
                                                <BsArrowUp />
                                                +{
                                                    getPercentageDifference(
                                                        findCarrierByName("Aramex")?.cost_price,
                                                        findCarrierByName("Aramex")?.margin_value
                                                    )
                                                }%
                                            </span> :
                                            <span className="percentText percentLoss" >
                                                <BsArrowDown />
                                                -{
                                                    getPercentageDifference(
                                                        findCarrierByName("Aramex")?.cost_price,
                                                        findCarrierByName("Aramex")?.margin_value
                                                    )
                                                }%
                                            </span> : null
                                }
                            </span>
                        </div>
                    </div>

                    <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center" >
                        <input name="shipment_processor" checked={anka} onChange={(e) => toggleShipmentTypeCheckBox("anka")}
                            className="form-check-input" type="radio" />
                        <div className="d-flex w-100 justify-content-between" >
                            <label className="form-check-label" htmlFor="allowedShipment">
                                Anka
                            </label>
                        </div>
                    </div>

                    {
                        manualApprove && (
                            <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center">
                                <div className="row gap-3 my-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-check-label">
                                            Enter Tracking Number
                                        </label>
                                        <input 
                                            type="tel" 
                                            name="tracking_number"
                                            value={trackingNumber}
                                            onChange={e => setTrackingNumber(e.target.value)}
                                            id="tracking_number"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex gap-2 align-items-center">
                                            <label htmlFor="" className="form-check-label">
                                                Upload Waybill Document
                                            </label>
                                            {
                                                waybillLoading && (<span className="spinner-border spinner-grow-sm"/>)
                                            }
                                        </div>
                                        <input 
                                            type="file" 
                                            name="waybill"
                                            value={waybillFile}
                                            onChange={uploadWaybillDocument}
                                            id="waybill"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
               
                <SubmitButton onClick={(e) => approveShipment("")} type="button" className="confirm-button">
                    {isSubmitting === false ? (
                        <span className="button-text">APPROVE SHIPMENT</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ApproveInternationalShipmentDialog;
