import "../styles/home.scss";
import "../styles/dashboard.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import DashboardGraph from "./Widgets/DashboardGraph";
import OverallStatistics from "./Widgets/OverallStatistics";
import TransporterActivities from "./Widgets/TransporterActivities";
import RecentShipments from "./Widgets/RecentShipments";
import RecentTransactions from "./Widgets/RecentTransactions";
import { SessionManagement } from "../library/SessionManagement";
import UserActivities from "./Widgets/UserActivities";
import {useEffect, useState} from "react";
import axios from "axios";
import {getDashboardStatisticsURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {URLConnection} from "../library/URLConnection";
import SubscriptionInactive from "../components/SubscriptionInactive";

const Dashboard = () => {
  const [totalRevenue, setTotalRevenue] = useState("...");
  const [totalShipments, setTotalShipments] = useState("...");
  const [totalCustomers, setTotalCustomers] = useState("...");
  const [totalTransactions, setTotalTransactions] = useState("...");

  const [shipmentLabels, setShipmentLabels] = useState([]);
  const [shipmentValues, setShipmentValues] = useState([]);

  useEffect(() => {
    URLConnection(getDashboardStatisticsURL, UserProfileData(), function (response){
      if (response["data"]["success"] === false) return;

      const totalRevenue = response["data"]["overall"]["total_revenue"];
      const totalShipments = response["data"]["overall"]["shipments"];
      const totalCustomers = response["data"]["overall"]["customers"];
      const totalTransactions = response["data"]["overall"]["growth"];

      setTotalRevenue(parseFloat(totalRevenue).toLocaleString("en"));
      setTotalShipments(parseFloat(totalShipments).toLocaleString("en"));
      setTotalCustomers(parseFloat(totalCustomers).toLocaleString("en"));
      setTotalTransactions(
          parseFloat(totalTransactions).toLocaleString("en")
      );

      let shipmentsValues = [];
      let shipmentLabels = [];

      let shipments = response["data"]["shipments"];

      JSON.parse(JSON.stringify(shipments), function (key, value) {
        shipmentLabels.push(key);
        shipmentsValues.push(value);
      });

      setShipmentLabels(shipmentLabels);
      setShipmentValues(shipmentsValues);
    });
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>Dashboard</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid">
            <OverallStatistics
                totalCustomers={totalCustomers}
                totalRevenue={totalRevenue}
                totalShipments={totalShipments}
                totalTransactions={totalTransactions}
            />

            <div className="row mt-4">
              <div className="col-xl-6">
                <div className="card bg-light border-0 shadow">
                  <h6 className="m-4">Shipments Analytics</h6>
                  <DashboardGraph
                      label={shipmentLabels}
                      shipmentsData={shipmentValues} />
                </div>
              </div>

              <RecentTransactions />
            </div>

            <div className="row mt-4">
              <RecentShipments />

              <TransporterActivities />

              <UserActivities />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
