import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {getShipmentsByUserURL, getShipmentsURL} from "../../../library/URLs";
import styled from "styled-components";
import moment from "moment";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomerProfileShipments = ({customerID}) => {
    const navigator = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [shipments, setShipments] = useState([]);

    const [currentOffsetDate, setCurrentOffsetDate] = useState("");
    const [currentOffsetID, setCurrentOffsetID] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);

    const data = {
        customer_id: customerID,
        limit: 20,
        offset_date: currentOffsetDate,
        offset_id: currentOffsetID,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };
    console.log(newData);

    // const setScroll = () => {
    //     // if ((window.innerHeight + window.scrollY) >=
    //     //     document.getElementById("shipments-table-container").offsetHeight
    //     //     && isRetrievingMore === false){
    //     //     // setIsRetrievingMore(true);
    //     //     console.log("retrieving");
    //     // }
    //     if (window.innerHeight === document.getElementById("shipments-table-container").offsetHeight){
    //         console.log("okay");
    //     }
    // };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            console.log("bottom")
        }
    }

    // React.useEffect(() => {
    //     window.addEventListener("scroll", setScroll);
    //     return () => {
    //         window.removeEventListener("scroll", setScroll);
    //     };
    // }, []);

    useEffect(() => {
        axios
            .post(getShipmentsByUserURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const shipments = response["data"]["data"];

                console.log(shipments.length);

                if (shipments.length === 0)
                    setIsEmpty(true);

                setShipments(shipments);
            });
    }, []);

    const handleClick = (shipmentID) => {
        navigator("/shipment/" + shipmentID);
    };

    return (
        <div className="card bg-light border-0 shadow">
            <div className="card-body">
                <span className="header">Customer Shipments</span>

                <div onScroll={handleScroll} className="shipments-table-container">
                    <table  className="shipments-table">
                        <thead>
                        <tr>
                            <th scope="col">Receiver</th>
                            <th scope="col">Dropoff Location</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date</th>
                        </tr>
                        </thead>
                        {isLoading === true ? null : (
                            <tbody>
                            {shipments.map((shipment) => {
                                const dateTime = moment(shipment.date_time);
                                const timestamp = dateTime.format('ddd MMM Do, h:mm A');

                                return (
                                    <tr style={{fontSize:"14.4px"}} key={shipment.id} onClick={() => handleClick(shipment.id)}>
                                        <td>{shipment.recipient_name}</td>
                                        <td>{shipment.drop_off_location}</td>
                                        <td>
                                            ₦
                                            {parseFloat(shipment.total_amount).toLocaleString(
                                                "en"
                                            )}
                                        </td>
                                        <td>{timestamp}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>
                </div>
                {isLoading === false ? null : (
                    <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                    </LoadingContainer>
                )}
                {isEmpty === false ? null :
                    <EmptyContainer>
                        <p>Oops... Sorry, customer has no recorded shipments</p>
                    </EmptyContainer>
                }
            </div>
        </div>
    );
}

export default CustomerProfileShipments;