import {Col, Modal, Row} from "react-bootstrap";
import {useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {getContractDetailsUserURL, makeContractUserURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {BiX} from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const MakeContractUserDialog = ({ isModalVisible, handleCloseDialog, customerID, getContractDetails }) => {
    const form = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRetrieving, setIsRetrieving] = useState(getContractDetails);

    const [localShipmentIsChecked, setLocalShipmentIsChecked] = useState(false);
    const [interstateShipmentIsChecked, setInterstateShipmentIsChecked] = useState(false);
    const [internationalShipmentIsChecked, setInternationalShipmentIsChecked] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [localPricingType, setLocalPricingType] = useState("Standard Price");
    const [localPricingValue, setLocalPricingValue] = useState("");
    const [localPaymentPlan, setLocalPaymentPlan] = useState("No Payment Plan");

    const [interstatePricingType, setInterstatePricingType] = useState("Standard Price");
    const [interstatePricingValue, setInterstatePricingValue] = useState("");
    const [interstatePaymentPlan, setInterstatePaymentPlan] = useState("No Payment Plan");

    const [internationalPricingType, setInternationalPricingType] = useState("Standard Price");
    const [internationalPricingValue, setInternationalPricingValue] = useState("");
    const [internationalPaymentPlan, setInternationalPaymentPlan] = useState("No Payment Plan");

    const [localPricingValueLabel, setLocalPricingValueLabel] = useState("Standard Price For Local");
    const [localPricingValueReadOnly, setLocalPricingValueReadOnly] = useState(true);

    const [interstatePricingValueLabel, setInterstatePricingValueLabel] = useState("Standard Price For Interstate");
    const [interstatePricingValueReadOnly, setInterstatePricingValueReadOnly] = useState(true);

    const [internationalPricingValueLabel, setInternationalPricingValueLabel] = useState("Standard Price For Int'l");
    const [internationalPricingValueReadOnly, setInternationalPricingValueReadOnly] = useState(true);

    const toggleLocalShipment = () => {
        setLocalShipmentIsChecked(!localShipmentIsChecked);
    }

    const toggleInterstateShipment = () => {
        setInterstateShipmentIsChecked(!interstateShipmentIsChecked);
    }

    const toggleInternationalShipment = () => {
        setInternationalShipmentIsChecked(!internationalShipmentIsChecked);
    }

    if (getContractDetails === true){
        const formValues = {};
        formValues['customer_id'] = customerID;

        const newData = { ...formValues, ...UserProfileData() };

        axios
            .post(getContractDetailsUserURL, newData)
            .then(function (response) {
                setIsRetrieving(false);

                if (response["data"]["success"] === true) {
                    const contractDetails = response["data"]["data"];

                    contractDetails.map((details) => {
                        console.log(details);
                        const pricingType = details.pricing_type;
                        const pricingValue = details.pricing_value;
                        const paymentValue = details.payment_value;

                        if (details.shipment_type === "Local" && details.status === "active"){
                            setLocalShipmentIsChecked(true);

                            setLocalPricingType(pricingType);
                            setLocalPricingValue(pricingValue);
                            setLocalPaymentPlan(paymentValue);

                            if (pricingType !== "Standard Price"){
                                setLocalPricingValueReadOnly(false);
                            } else {
                                setLocalPricingValueReadOnly(true);
                            }
                        }
                        if (details.shipment_type === "Interstate" && details.status === "active"){
                            setInterstateShipmentIsChecked(true);

                            setInterstatePricingType(pricingType);
                            setInterstatePricingValue(pricingValue);
                            setInterstatePaymentPlan(paymentValue);

                            if (pricingType !== "Standard Price"){
                                setInterstatePricingValueReadOnly(false);
                            }
                        }
                        if (details.shipment_type === "International" && details.status === "active"){
                            setInternationalShipmentIsChecked(true);

                            setInternationalPricingType(pricingType);
                            setInternationalPricingValue(pricingValue);
                            setInternationalPaymentPlan(paymentValue);

                            if (pricingType !== "Standard Price"){
                                setInternationalPricingValueReadOnly(false);
                            }
                        }
                    });
                }
            })
            .catch(function (error) {
                return error;
            });
    }

    const makeContractUser = () => {
        const formValues = {};
        formValues['customer_id'] = customerID;

        const data = new FormData(form.current);

        if (localShipmentIsChecked){
            formValues['local_pricing_type'] = data.get("local_pricing_type");
            formValues['local_pricing_value'] = data.get("local_pricing_value");
            formValues['local_payment_plan'] = data.get("local_payment_plan");
        }
        if (interstateShipmentIsChecked){
            formValues['interstate_pricing_type'] = data.get("interstate_pricing_type");
            formValues['interstate_pricing_value'] = data.get("interstate_pricing_value");
            formValues['interstate_payment_plan'] = data.get("interstate_payment_plan");
        }
        if (internationalShipmentIsChecked){
            formValues['international_pricing_type'] = data.get("international_pricing_type");
            formValues['international_pricing_value'] = data.get("international_pricing_value");
            formValues['international_payment_plan'] = data.get("international_payment_plan");
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(makeContractUserURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("User has successfully been set to a contract user");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog("Refresh");
                    }, 3000);
                }
            })
            .catch(function (error) {
                return error;
            });
    }

    const onChangeLocalPricingType = event => {
        if (event.target.value === "Standard Price"){
            setLocalPricingValueLabel("Standard Price For Local");
            setLocalPricingValueReadOnly(true);
        } else if (event.target.value === "Fixed Price"){
            setLocalPricingValueLabel("Fixed Price For Local");
            setLocalPricingValueReadOnly(false);
        } else if (event.target.value === "Discounted Price"){
            setLocalPricingValueLabel("Percentage Off For Local");
            setLocalPricingValueReadOnly(false);
        }
    }

    const onChangeInterstatePricingType = event => {
        if (event.target.value === "Standard Price"){
            setInterstatePricingValueLabel("Standard Price For Interstate");
            setInterstatePricingValueReadOnly(true);
        } else if (event.target.value === "Fixed Price"){
            setInterstatePricingValueLabel("Fixed Price For Interstate");
            setInterstatePricingValueReadOnly(false);
        } else if (event.target.value === "Discounted Price"){
            setInterstatePricingValueLabel("Percentage Off For Interstate");
            setInterstatePricingValueReadOnly(false);
        }
    }

    const onChangeInternationalPricingType = event => {
        if (event.target.value === "Standard Price"){
            setInternationalPricingValueLabel("Standard Price For Int'l");
            setInternationalPricingValueReadOnly(true);
        } else if (event.target.value === "Fixed Price"){
            setInternationalPricingValueLabel("Fixed Price For Int'l");
            setInternationalPricingValueReadOnly(false);
        } else if (event.target.value === "Discounted Price"){
            setInternationalPricingValueLabel("Percentage Off For Int'l");
            setInternationalPricingValueReadOnly(false);
        }
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">MAKE CONTRACT USER</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          {isRetrieving === true ? (
              <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm"/> <span>Retrieving contract details...</span>
          </LoadingContainer>) :
              (<form ref={form} onSubmit={makeContractUser}>
                  <div className="local-shipment-form form-check">
                      <input checked={localShipmentIsChecked} onChange={toggleLocalShipment} className="form-check-input" type="checkbox" value="" id="localShipment" />
                      <label className="form-check-label" htmlFor="localShipment">
                          Local Shipment
                      </label>
                  </div>

                  {
                      localShipmentIsChecked === false ? null : <div>
                          <Row className="mt-3">
                              <Col>
                                  <label htmlFor="Sender Postal">Pricing Plan For Local</label>
                                  <select onChange={onChangeLocalPricingType} name="local_pricing_type" className="form-select" defaultValue={localPricingType}>
                                      <option value="Standard Price">Standard Price</option>
                                      <option value="Fixed Price">Fixed Price</option>
                                      <option value="Discounted Price">Discounted Price</option>
                                  </select>
                              </Col>
                              <Col>
                                  <label htmlFor="Fixed Price">{localPricingValueLabel}</label>
                                  <input name="local_pricing_value" className="form-control" type="text" defaultValue={localPricingValue} readOnly={localPricingValueReadOnly}/>
                              </Col>
                          </Row>

                          <div className="mt-2">
                              <label htmlFor="Payment Plan">Payment Plan</label>
                              <select name="local_payment_plan" className="form-select" defaultValue={localPaymentPlan}>
                                  <option value="No Payment Plan">No Payment Plan</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Monthly">Monthly</option>
                              </select>
                          </div>
                      </div>
                  }

                  <div className="local-shipment-form form-check mt-4">
                      <input checked={interstateShipmentIsChecked} onChange={toggleInterstateShipment} className="form-check-input" type="checkbox" value="" id="interstateShipment" />
                      <label className="form-check-label" htmlFor="interstateShipment">
                          Interstate Shipment
                      </label>
                  </div>

                  {
                      interstateShipmentIsChecked === false ? null : <div>
                          <Row className="mt-3">
                              <Col>
                                  <label htmlFor="Sender Postal">Pricing Plan For Interstate</label>
                                  <select onChange={onChangeInterstatePricingType} name="interstate_pricing_type" className="form-select" defaultValue={interstatePricingType}>
                                      <option value="Standard Price">Standard Price</option>
                                      <option value="Fixed Price">Fixed Price</option>
                                      <option value="Discounted Price">Discounted Price</option>
                                  </select>
                              </Col>
                              <Col>
                                  <label htmlFor="Fixed Price">{interstatePricingValueLabel}</label>
                                  <input name="interstate_pricing_value" className="form-control" type="text" defaultValue={interstatePricingValue} readOnly={interstatePricingValueReadOnly}/>
                              </Col>
                          </Row>

                          <div className="mt-2">
                              <label htmlFor="Payment Plan">Payment Plan For Interstate</label>
                              <select name="interstate_payment_plan" className="form-select" defaultValue={interstatePaymentPlan}>
                                  <option value="No Payment Plan">No Payment Plan</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Monthly">Monthly</option>
                              </select>
                          </div>
                      </div>
                  }

                  <div className="local-shipment-form form-check mt-4">
                      <input checked={internationalShipmentIsChecked} onChange={toggleInternationalShipment} className="form-check-input" type="checkbox" value="" id="internationalShipment" />
                      <label className="form-check-label" htmlFor="internationalShipment">
                          International Shipment
                      </label>
                  </div>

                  {
                      internationalShipmentIsChecked === false ? null : <div>
                          <Row className="mt-3">
                              <Col>
                                  <label htmlFor="Sender Postal">Pricing Plan For International</label>
                                  <select onChange={onChangeInternationalPricingType} name="international_pricing_type" className="form-select" defaultValue={internationalPricingType}>
                                      <option value="Standard Price">Standard Price</option>
                                      <option value="Fixed Price">Fixed Price</option>
                                      <option value="Discounted Price">Discounted Price</option>
                                  </select>
                              </Col>
                              <Col>
                                  <label htmlFor="Fixed Price">{internationalPricingValueLabel}</label>
                                  <input name="international_pricing_value" className="form-control" type="text" defaultValue={internationalPricingValue} readOnly={internationalPricingValueReadOnly}/>
                              </Col>
                          </Row>

                          <div className="mt-2">
                              <label htmlFor="Payment Plan">Payment Plan For International</label>
                              <select name="internationale_payment_plan" className="form-select" defaultValue={internationalPaymentPlan}>
                                  <option value="No Payment Plan">No Payment Plan</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Monthly">Monthly</option>
                              </select>
                          </div>
                      </div>
                  }
              </form>)
          }

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={makeContractUser} type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">MAKE CONTRACT USER</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default MakeContractUserDialog;
