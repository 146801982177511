import { Col, Modal, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
  addCashbackUrl,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { BiX } from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddCashbackDialog = ({
  isModalVisible,
  handleCloseDialog,
  getCashbacks,
}) => {
  const form = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    min_amount: "",
    max_amount: "",
    percent_value: "",
    description: ""
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addCashback = () => {

    const newData = { ...formData, ...UserProfileData() };


    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(addCashbackUrl, newData)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage(
            "Cashback successfully added!"
          );

          setTimeout(() => {
            setSuccessVisibility(false);
            getCashbacks();
            handleCloseDialog();
            setFormData({
              name: "",
              min_amount: "",
              max_amount: "",
              percent_value: "",
              description: ""
            });
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{ marginTop: 10 }} className="header">
          ADD CASHBACK
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <form ref={form} onSubmit={addCashback}>
          <div>
            <Row className="gy-3">
              <Col xs={12}>
                <label htmlFor="Name">Cashback Name</label>
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  value={formData.name}
                  onChange={handleInput}
                />
              </Col>

              <Col xs={12}>
                <label htmlFor="Name">Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  type="text"
                  value={formData.description}
                  onChange={handleInput}
                  rows={3}
                ></textarea>
              </Col>

              <Col xs={12} md={6}>
                <label htmlFor="Name">Minimum Spent</label>
                <input
                  name="min_amount"
                  className="form-control"
                  type="text"
                  value={formData.min_amount}
                  onChange={handleInput}
                />
              </Col>

              <Col xs={12} md={6}>
                <label htmlFor="Name">Maximum Spent</label>
                <input
                  name="max_amount"
                  className="form-control"
                  type="text"
                  value={formData.max_amount}
                  onChange={handleInput}
                />
              </Col>

              <Col xs={12} md={6}>
                <label htmlFor="Name">Cashback Value (%)</label>
                <input
                  name="percent_value"
                  className="form-control"
                  type="text"
                  value={formData.percent_value}
                  onChange={handleInput}
                />
              </Col>
              
            </Row>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          onClick={addCashback}
          type="submit"
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">PROCEED</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCashbackDialog;
