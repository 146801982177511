import { Modal } from "react-bootstrap";
import axios from "axios";
import { fundCashbackUrl } from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const FundCashbackDialog = ({
  isModalVisible,
  handleCloseDialog,
  customer_id,
  amount,
  period,
  employee_name,
  fetchCashbacks,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const fundCashback = (e) => {
    e.preventDefault();
    const formValues = {
      customer_id,
      amount,
      period,
    };

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(fundCashbackUrl, newData)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Customer has been successfully funded!");

          setTimeout(() => {
            setSuccessVisibility(false);
            fetchCashbacks(period);
            handleCloseDialog();
          }, 5000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{ marginTop: 10 }} className="header">
          FUND CASHBACK
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>
          You are about to fund &#8358;{parseFloat(amount)?.toLocaleString("en-US")} into {employee_name}'s wallet
        </p>
        <p>Would you like to proceed?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          onClick={fundCashback}
          type="submit"
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">FUND CUSTOMER</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default FundCashbackDialog;
