import "../../styles/home.scss";
import "../../styles/reports.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserProfileData} from "../../library/constants";
import {getShipmentsURL, getTransporterReportsURL, getUserReportsURL} from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import dataIcon from "../../images/no-data.png";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const TransporterReports = () => {
  const navigator = useNavigate();

  const [reportType, setReportType] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [isOptionSelected, setIsOptionSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [shipments, setShipments] = useState([]);

  const data = {
    limit: 1000,
    offset: currentOffset,
    report_type:reportType,
    report_time:reportTime,
    status: "All"
  };

  const newData = { ...data, ...UserProfileData() };

  const getReports = () => {
    if (reportTime !== "" && reportType !== ""){
      setIsLoading(true);
      setCurrentOffset(0);
      setIsOptionSelected(reportType + reportTime);

      getTransporterReports();
    }
  }

  const getTransporterReports = () => {
    axios
        .post(getTransporterReportsURL, newData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          setIsRetrievingMore(false)
          setIsLoading(false);

          if (response["data"]["success"] === false) return;

          const newShipments = response["data"]["data"];

          const index = newShipments[newShipments.length - 1].id;

          setCurrentOffset(index);

          if (currentOffset === 0){
            setShipments(newShipments);
          } else {
            setShipments([...shipments, ...newShipments]);
          }
        });
  }

  const onChangeReportType = event => {
    setReportType(event.target.value)
  }

  const onChangeReportTime = event => {
    setReportTime(event.target.value);
  }

  const handleClick = (customerID) => {
    navigator("/customer/" + customerID);
  };

  const setScroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && isRetrievingMore === false){
      setIsRetrievingMore(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const exportToCSV = () => {
    if (shipments.length === 0)
      return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += "Transporter Name,Number of Shipments, Worth of Shipment, Period"  + "\r\n";

    shipments.forEach(function(shipment) {
      let row = shipment.user + "," + shipment.shipments_number+ "," + shipment.shipments_fee
          + "," + shipment.period;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI)
    link.setAttribute("download", "customer-reports.csv")
    document.body.appendChild(link)

    link.click();
  }

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>Transporter Reports</h5>
            <span>Home</span>
          </div>
          <div className="container shipments-container">
            <div className="card bg-light border-0 shadow p-4 min-vh-100">

              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <select onChange={onChangeReportType} style={{width:230, marginRight:10}} name="pickup_detail_id" className="form-select" >
                    <option value="">Choose report</option>
                    <option value="shipments per transporter">Shipments Per Transporter</option>
                  </select>

                  <select onChange={onChangeReportTime} style={{width:200, marginRight:10}} name="pickup_detail_id" className="form-select" >
                    <option value="">Choose date</option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="this week">This Week</option>
                    <option value="this month">This Month</option>
                    <option value="custom">Custom Date</option>
                  </select>

                  <button onClick={getReports} className="confirm-button">
                    <BiIcons.BiSearch style={{fontSize:20}}/>
                  </button>
                </div>

                <input className="confirm-button" type="button" onClick={exportToCSV} value="EXPORT TO CSV" />
              </div>


              <table className="reports-table">
                <thead>
                  <tr>
                    <th scope="col">Sender</th>
                    <th scope="col">Number of Shipments</th>
                    <th scope="col">Revenue of Shipments</th>
                    <th scope="col">Period</th>
                  </tr>
                </thead>
                {isLoading === true ? null : (
                  <tbody>
                  {shipments.map((shipment) => {
                    return (
                        <tr key={shipment.user_id} onClick={() => handleClick(shipment.id)}>
                          <td>{shipment.user}</td>
                          <td>{shipment.shipments_number}</td>
                          <td>₦{parseFloat(shipment.shipments_fee).toLocaleString("en")}</td>
                          <td>{shipment.period}</td>
                        </tr>
                    );
                  })}
                  </tbody>
                )}
              </table>
              {isRetrievingMore === false || isOptionSelected === "" || isLoading === true ? null :
              <div style={{textAlign:"center", marginTop:40}}>
                <LoadingSpinner className="spinner-border spinner-grow-sm"/> Retrieving More..
              </div>
              }
              {isOptionSelected !== "" ? null : (
                  <LoadingContainer>
                    <div>
                      <img style={{width:50, height:50, marginBottom:10}} src={dataIcon} alt="" />
                      <p>No option has been selected</p>
                    </div>
                  </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransporterReports;
