import { useState, useRef, useEffect } from "react";
import {Col, Overlay, Row, Tooltip} from "react-bootstrap";
import axios from "axios";
import {
  calculateTerminalURL,
  createTerminalInterShipment, getAddressSuggestionsURL,
  getDHLPickupLocationsURL,
  getPackagingURL, getStatesURL, validateAddressURL
} from "../../../library/URLs";
import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";
import { generateTransactionID } from "../../../library/utilities";
import { UserProfileData, countryCodes } from "../../../library/constants";
import "react-datepicker/dist/react-datepicker.css";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import * as BiIcons from "react-icons/bi";
import * as FiIcons from "react-icons/fi";
import "../../../styles/create-inter-shipment.scss";
import StateListDialog from "../../../modals/StateListDialog";
import CityListDialog from "../../../modals/CitiesListDialog";
import phoneList from '../../../files/phone.json';

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right:15px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor:pointer;
  padding-left:2px
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const CreateInterstateTerminalShipmentForm = () => {
  const navigator = useNavigate();

  const form = useRef(null);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [contentFields, setContentFields] = useState(3);

  const [senderCountryCode, setSenderCountryCode] = useState("NG");

  const [verifiableReceiverAddress, setVerifiableReceiverAddress] = useState("");

  const [isVerifyingPickupAddress, setIsVerifyingPickupAddress] = useState(false);
  const [isVerifyingDropoffAddress, setIsVerifyingDropoffAddress] = useState(false);

  const [receiverAddress1, setReceiverAddress1] = useState("");
  const [receiverAddress2, setReceiverAddress2] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverState, setReceiverState] = useState("");
  const [receiverPostalCode, setReceiverPostalCode] = useState("");
  const [receiverCountryCode, setReceiverCountryCode] = useState("NG");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");

  const [useSavedAddress, setUseSavedAddress] = useState(true);

  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [pickupAddressSuggestions, setPickupAddressSuggestions] = useState([]);
  const [dropoffAddressSuggestions, setDropoffAddressSuggestions] = useState([]);
  const [showDropoffSuggestions, setShowDropoffSuggestions] = useState(false);
  const [showPickupSuggestions, setShowPickupSuggestions] = useState(false);

  const [isCalculatingShipment, setIsCalculatingShipment] = useState(false);
  const [isCreatingShipment, setIsCreatingShipment] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [stateDialogVisibility, setStateDialogVisibility] = useState(false);
  const [cityDialogVisibility, setCityDialogVisibility] = useState(false);

  const [carriers, setCarriers] = useState([]);

  const [selectedCarrierID, setSelectedCarrierID] = useState("");
  const [selectedCarrierAmount, setSelectedCarrierAmount] = useState("");

  const [pickupLocations, setPickupLocations] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);

  const [pickupAddressID, setPickupAddressID] = useState("");
  const [dropoffAddressID, setDropoffAddressID] = useState("");
  const [parcelID, setParcelID] = useState("");

  const [isShipmentCalculated, setIsShipmentCalculated] = useState(false);
  const [showPhase2Shipment, setShowPhase2Shipment] = useState(false);

  const [listOfStates, setListOfStates] = useState([]);

  useEffect(() => {
    axios
        .post(getDHLPickupLocationsURL, UserProfileData())
        .then(function (response) {
          console.log(response);
          if (response["data"]["success"] === true) {
            const pickupLocations = response["data"]["data"];
            console.log(pickupLocations);

            setPickupLocations(pickupLocations);
          }
        })
        .catch(function (error) {
          return error;
        });
  }, []);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();

    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;

    formValues["amount"] = selectedCarrierAmount;
    formValues["carrier_id"] = selectedCarrierID;
    formValues["parcel_id"] = parcelID;
    formValues["pickup_address_id"] = pickupAddressID;
    formValues["dropoff_address_id"] = dropoffAddressID;

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    setIsCreatingShipment(true);

    axios
      .post(createTerminalInterShipment, newData)
      .then(function (response) {
        console.log(response);
        setIsCreatingShipment(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);
        } else if (response["data"]["success"] === true) {
          window.scrollTo(0, 0);

          const shipmentID = response["data"]["shipment_id"];
          console.log(shipmentID);

          setSuccessVisibility(true);
          setSuccessMessage(
            "Shipment has been successfully created. Redirecting..."
          );

          setTimeout(() => {
            navigator("/shipment/" + shipmentID);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const selectPlace = (location, type) => {
    if (type === "Pickup"){
      setPickupLocation(location);
      setShowPickupSuggestions(false);
    } else {
      setDropoffLocation(location);
      setShowDropoffSuggestions(false);
    }

    validateAddress(location);
  }

  const onSelectSenderCountry = (code) => {
    setSenderCountryCode(code);
  }

  const onSelectReceiverCountry = (code) => {
    setReceiverCountryCode(code);
    setReceiverState("");
    const countryCode = isNaN(code.substring(0, 1)) === false ? phoneList[code] : "+" + phoneList[code];
    setReceiverPhoneNumber(countryCode);
  }

  const validateAddress = (type, address) => {
    const data = {
      address: address,
      country:receiverCountryCode
    };

    console.log(data);

    const newData = { ...data, ...UserProfileData() };

    setIsVerifyingDropoffAddress(true);

    axios
        .post(validateAddressURL, newData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsVerifyingDropoffAddress(false);

          const predictions = response["data"]["data"];

          const address1 = predictions["address_1"];
          const address2 = predictions["address_2"];
          const city = predictions["city"];
          const state = predictions["state"];
          const postalCode = predictions["postal_code"];

          if (type === "Pickup"){
            setPickupAddressSuggestions(predictions);
          } else {
            setReceiverAddress1(address1);
            setReceiverAddress2(address2);
            setReceiverCity(city);
            setReceiverState(state);
            setReceiverPostalCode(postalCode);
          }

          console.log(predictions);
        });
  }

  const suggestPlaces = (event, type) => {
    if (isShipmentCalculated === true){
      setIsShipmentCalculated(false);
      setShowPhase2Shipment(false);
    }

    const keyword = event.target.value;

    const newData = {
      "keyword" : keyword
    };

    if (type === "Pickup"){
      setPickupLocation(keyword);
      setShowPickupSuggestions(true);
    } else {
      setDropoffLocation(keyword);
      setShowDropoffSuggestions(true);
    }

    axios
        .post(getAddressSuggestionsURL, newData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          const predictions = response["data"]["predictions"];

          if (type === "Pickup"){
            setPickupAddressSuggestions(predictions);
          } else {
            setDropoffAddressSuggestions(predictions);
          }

          console.log(predictions);
        });
  }

  const calculateTerminalPrice = () => {
    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;

    const newData = { ...formValues, ...UserProfileData() };

    setIsCalculatingShipment(true);

    console.log(newData);

    axios
        .post(calculateTerminalURL, newData)
        .then(function (response) {
          console.log(response);
          setIsCalculatingShipment(false);
          if (response["data"]["success"] === true) {
            const pickupAddressID = response["data"]["pickup_address_id"];
            const dropoffAddressID = response["data"]["delivery_address_id"];
            const parcelID = response["data"]["parcel_id"];
            const carriers = response["data"]["data"];

            carriers.forEach((carrier) => {
              carrier["selected"] = false;
            });

            setPickupAddressID(pickupAddressID);
            setDropoffAddressID(dropoffAddressID);
            setParcelID(parcelID);

            setCarriers(carriers);
            setShowPhase2Shipment(true);
            setIsShipmentCalculated(true);
          } else {
            window.scrollTo(0, 0);
            setErrorVisibility(true);
            setErrorMessage(response["data"]["message"]);

            setTimeout(() => {
              setErrorVisibility(false);
            }, 5000);

            console.log("Not getting here");
          }
        })
        .catch(function (error) {
          return error;
        });
  }

  const showStatesDialog = () => {
    setStateDialogVisibility(true);
  }
  const closeStatesDialog = (state) => {
    setStateDialogVisibility(false);

    console.log(state);

    if (typeof state === 'string'){
      setReceiverState(state);
    }
  }

  const showCitiesDialog = () => {
    setCityDialogVisibility(true);
  }

  const closeCitiesDialog = (city) => {
    setCityDialogVisibility(false);

    console.log(city);

    if (typeof city === 'string'){
      setReceiverCity(city);
    }
  }

  const createContentFields = () => {
    const td = []
    for (let index = 1; index <= contentFields; index++){
      td.push(
          <Row className="form-row mb-3">
            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Length">Description</label>
              <input
                  name={`content_description_${index}`}
                  className="form-control"
                  type="text"
                  placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-1 col-md-6">
              <label htmlFor="Weight">Weight</label>
              <input
                  name={`content_weight_${index}`}
                  className="form-control"
                  type="text"
                  placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-1 col-md-6">
              <label htmlFor="Quantity">Quantity</label>
              <input
                  name={`content_quantity_${index}`}
                  className="form-control"
                  type="text"
                  placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Value">Value </label>
              <input
                  name={`content_value_${index}`}
                  className="form-control"
                  type="number"
              />
            </Col>

            <Col className="form-group col-xl-2 col-md-6">
              <label htmlFor="Currency">Currency</label>
              <select name={`content_currency_${index}`} className="form-select">
                <option value="">Select Currency</option>
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
            </Col>
          </Row>
      );
    }
    return td;
  }

  const handleContentFieldsIncrease = () => {
    if (contentFields < 5){
      setContentFields(contentFields + 1);
    }
  }

  const handleContentFieldsDecrease = () => {
    if (contentFields > 1){
      setContentFields(contentFields - 1);
    }
  }

  const fieldOnChange = (event, fieldType) => {
    if (isShipmentCalculated === true){
      setIsShipmentCalculated(false);
      setShowPhase2Shipment(false);
    }

    if (fieldType === "ReceiverCity"){
      setReceiverCity(event.target.value);
    } else if (fieldType === "ReceiverState"){
      setReceiverState(event.target.value);
    } else if (fieldType === "ReceiverPostalCode"){
      setReceiverPostalCode(event.target.value);
    } else if (fieldType === "ReceiverAddress1"){
      setReceiverAddress1(event.target.value);
    } else if (fieldType === "ReceiverAddress2"){
      setReceiverAddress2(event.target.value);
    } else if (fieldType === "receiverPhoneNumber"){
      setReceiverPhoneNumber(event.target.value);
    }
  }

  const toggleSetUseSavedAddress = () => {
    setUseSavedAddress(!useSavedAddress);
    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);
  }

  return (
    <div className="">
      {
        isError === false ? null :
            (<ErrorMessageContainer>
              {errorMessage}
            </ErrorMessageContainer>)
      }

      {
        isSuccess=== false ? null :
            (<SuccessMessageContainer>
              {successMessage}
            </SuccessMessageContainer>)
      }

      <StateListDialog
          isModalVisible={stateDialogVisibility}
          countryCode={receiverCountryCode}
          handleCloseDialog={closeStatesDialog}
      />

      <CityListDialog
          isModalVisible={cityDialogVisibility}
          countryCode={receiverCountryCode}
          stateName={receiverState}
          handleCloseDialog={closeCitiesDialog}
      />

      <form ref={form} onSubmit={handleSubmit}>
        <section>
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>1</SteppedNumber>
            <SteppedTitle className="mx-2">Package Details</SteppedTitle>
          </div>
          <Row className="form-row mt-3">
            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Weight">Weight</label>
              <input onChange={(e) => fieldOnChange(e, "")} name="weight" className="form-control"  type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Length">Length</label>
              <input onChange={(e) => fieldOnChange(e, "")} name="length" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Breadth">Breadth</label>
              <input onChange={(e) => fieldOnChange(e, "")} name="breadth" className="form-control"  type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Height">Height</label>
              <input onChange={(e) => fieldOnChange(e, "")} name="height" className="form-control"  type="text" />
            </Col>
          </Row>
        </section>

        <section>
          <div className="d-flex align-items-center mt-5">
            <SteppedNumber>2</SteppedNumber>
            <SteppedTitle className="mx-2">Sender Details</SteppedTitle>
          </div>
          <Row className="form-row">
            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Full Name">First Name</label>
              <input onChange={(e) => fieldOnChange(e, "")} name="sender_first_name" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Full Name">Last Name</label>
              <input onChange={(e) => fieldOnChange(e, "")} name="sender_last_name" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Email">Email</label>
              <input onChange={(e) => fieldOnChange(e, "")}
                name="sender_email"
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Phone Number">Phone Number</label>
              <input onChange={(e) => fieldOnChange(e, "")}
                name="sender_phone"
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>
          </Row>

          {
            useSavedAddress === true ? null :
            <div>
              <Row className="form-row mt-3">
                <Col className="form-group col-xl-6 col-md-6">
                  <label htmlFor="Address">Address</label>
                  <input onChange={fieldOnChange} name="sender_address" className="form-control" type="text" maxLength={45} />
                </Col>

                <Col className="form-group col-xl-6 col-md-6">
                  <label htmlFor="Address">Address 2 (Optional)</label>
                  <input onChange={(e) => fieldOnChange(e, "SenderAddress2")} name="sender_address_2" className="form-control" type="text" placeholder="" maxLength={45} />
                </Col>
              </Row>

              <Row className="form-row mt-3">
                <Col className="form-group col-xl-3 col-md-6">
                  <label htmlFor="Sender Country">Country</label>
                  <ReactFlagsSelect
                      className="countries-text-field"
                      selected={senderCountryCode}
                      onSelect={onSelectSenderCountry}
                      countries={["fi", "NG"]}
                  />
                </Col>

                <Col className="form-group col-xl-3 col-md-6">
                  <label htmlFor="State">State</label>
                  <input onChange={(e) => fieldOnChange(e, "SenderState")} name="sender_state" className="form-control"  type="text" />
                </Col>

                <Col className="form-group col-xl-3 col-md-6">
                  <label htmlFor="Sender City">City</label>
                  <input onChange={(e) => fieldOnChange(e, "SenderCity")} name="sender_city" className="form-control" type="text" />
                </Col>

                <Col className="form-group col-xl-3 col-md-6">
                  <label htmlFor="Sender Postal">Postal Code</label>
                  <input onChange={(e) => fieldOnChange(e, "SenderPostalCode")}
                         name="sender_postal_code"
                         className="form-control"
                         type="text"
                  />
                </Col>
              </Row>
            </div>
          }

          <div className="mt-3">
            <Row>
              <Col className="form-group col-xl-6 col-md-6">
                <div className="local-shipment-form form-check">
                  <input name="use_saved_pickup_address" checked={useSavedAddress} onChange={toggleSetUseSavedAddress} className="form-check-input" type="checkbox" value="yes"  />
                  <label className="form-check-label" htmlFor="localShipment">
                    Use A Saved Address
                  </label>
                </div>
              </Col>

              {
                useSavedAddress === false ? null :
                    <Col className="form-group col-xl-6 col-md-6">
                      <label htmlFor="Sender Country">Preferred Pickup Location</label>
                      <select name="pickup_address_id" className="form-select"  >
                        <option value="">Choose pickup location</option>
                        {pickupLocations.map((locations) => {
                          return(<option key={locations.id} value={locations.live_terminal_africa_id}>{locations.address}</option>);
                        })}
                      </select>
                    </Col>
              }
            </Row>
          </div>
        </section>

        <section className="mt-5">
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>3</SteppedNumber>
            <SteppedTitle className="mx-2">Receiver Details</SteppedTitle>
          </div>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Address">Address</label>
              <input onChange={(e) => fieldOnChange(e, "ReceiverAddress1")} value={receiverAddress1}
                name="receiver_address" className="form-control" type="text" maxLength={45}/>
            </Col>

            <Col className="form-group col-xl-6 col-md-6">
              <label htmlFor="Address">Address 2 (Optional)</label>
              <input onChange={(e) => fieldOnChange(e, "ReceiverAddress2")} value={receiverAddress2}
                name="receiver_address_2"
                className="form-control"
                type="text"
                placeholder=""
                maxLength={45}
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-4 col-md-6">
              <div className="d-flex flex-row justify-content-between">
                <label htmlFor="Receiver State">State</label>
                {isVerifyingDropoffAddress === true ? <span className="spinner-grow spinner-grow-sm text-success"/> : null}
              </div>
              <input onChange={(e) => fieldOnChange(e, "ReceiverState")} value={receiverState}
                     name="receiver_county" className="form-control" type="text" />
              <a onClick={(event) => showStatesDialog()} className="button-text" style={{fontSize:13, textDecoration:"none", cursor:"pointer"}}>See list of possible states</a>
            </Col>

            <Col className="form-group col-xl-4 col-md-6">
              <div className="d-flex flex-row justify-content-between">
                <label htmlFor="Receiver City">City</label>
                {isVerifyingDropoffAddress === true ? <span className="spinner-grow spinner-grow-sm text-success"/> : null}
              </div>
              <input onChange={(e) => fieldOnChange(e, "ReceiverCity")} value={receiverCity}
                name="receiver_city" className="form-control" type="text" />
              <a onClick={(event) => showCitiesDialog()} className="button-text" style={{fontSize:13, textDecoration:"none", cursor:"pointer"}}>See list of possible cities</a>
            </Col>

            <Col className="form-group col-xl-4 col-md-6">
              <div className="d-flex flex-row justify-content-between">
                <label htmlFor="Receiver Postal Code">Postal Code</label>
                {isVerifyingDropoffAddress === true ? <span className="spinner-grow spinner-grow-sm text-success"/> : null}
              </div>
              <input onChange={(e) => fieldOnChange(e, "ReceiverPostalCode")} value={receiverPostalCode}
                name="receiver_postal_code" className="form-control" type="text" />
            </Col>
          </Row>

          <Row className="form-row mt-2">
            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="First Name">First Name</label>
              <input name="receiver_first_name" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Last Name">Last Name</label>
              <input onChange={(e) => fieldOnChange(e, "")} name="receiver_last_name" className="form-control"  type="text"
              />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Email">Email</label>
              <input onChange={(e) => fieldOnChange(e, "")}
                     name="receiver_email"
                     className="form-control"
                     type="text"
                     placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-3 col-md-6">
              <label htmlFor="Phone Number">Phone Number</label>
              <input onChange={(e) => fieldOnChange(e, "receiverPhoneNumber")} value={receiverPhoneNumber}
                     name="receiver_phone" className="form-control" type="text" placeholder="" />
            </Col>
          </Row>

          {isShipmentCalculated === false && isCalculatingShipment === false ? (
              <div className="d-flex justify-content-end mt-5">
                <SubmitButton onClick={calculateTerminalPrice} type="button" className="confirm-button">
                  <span className="button-text">CALCULATE COST</span>
                </SubmitButton>
              </div>
          ) : isShipmentCalculated === true ? null : (
              <div className="d-flex justify-content-end mt-5">
                <SubmitButton onClick={calculateTerminalPrice} type="button" className="confirm-button">
                  <span className="spinner-border spinner-grow-sm"/>
                </SubmitButton>
              </div>
          )}
        </section>

        {showPhase2Shipment === false ? null :
        <div>
          <section className="mt-5">
            <div className="d-flex align-items-center mb-2">
              <SteppedNumber>4</SteppedNumber>
              <SteppedTitle className="mx-2">Carriers <span style={{fontSize:15, fontStyle:"italic"}}>(Select a carrier)</span></SteppedTitle>
            </div>
            <table style={{width:"100%"}}>
              <tbody style={{width:"100%"}}>
              {carriers.map((carrier) => {
                const selectCarrier = (selectedCarrier) => {
                  let newCarriers = [...carriers];
                  newCarriers.forEach((transporter) => {
                    transporter["selected"] = false;
                  });

                  const carrierIndex = carriers.findIndex((carrier) => carrier.carrier_id === selectedCarrier.carrier_id);

                  newCarriers[carrierIndex] = {...newCarriers[carrierIndex],
                    selected: !newCarriers[carrierIndex].selected};

                  setCarriers(newCarriers);

                  setSelectedCarrierAmount(carrier.amount);
                  setSelectedCarrierID(selectedCarrier.carrier_id);
                }

                return (
                    <tr onClick={()=> selectCarrier(carrier)} style={{cursor:"pointer"}} key={carrier.carrier_id}>
                      <td className="description" style={{width:100}}>
                        <div className="d-flex align-items-center">
                          <img className="icon" src={carrier.carrier_logo} alt="" />
                        </div>
                      </td>
                      <td className="amount">
                        <div className="d-flex flex-column">
                          <span style={{fontSize:18}}>{carrier.carrier_name}</span>
                          <span>{carrier.delivery_time}</span>
                        </div>
                      </td>
                      <td className="amount" style={{fontSize:20}}>₦{parseFloat(carrier.amount).toLocaleString("en")}</td>
                      <td className="status">
                        {carrier.selected === false ? null :
                            <div style={{borderRadius:"50%", float:"right", height:40, width:40, backgroundColor: "#008561", textAlign:"center"}}>
                              <BiIcons.BiCheck style={{fontSize:25, color: "white", marginTop:7}}/>
                            </div>
                        }
                      </td>
                    </tr>
                );
              })}
              </tbody>
            </table>
          </section>

          <section className="mt-5">
            <div className="d-flex align-items-center mb-2">
              <SteppedNumber>5</SteppedNumber>
              <SteppedTitle className="mx-2">Content Details</SteppedTitle>
            </div>
            { createContentFields() }
            <div className="d-flex justify-content-center">
              <ContentFieldsActionButton onClick={handleContentFieldsDecrease}>-</ContentFieldsActionButton>
              <ContentFieldsActionButton onClick={handleContentFieldsIncrease}>+</ContentFieldsActionButton>
            </div>
          </section>

          <section className="mt-5">
            <div className="d-flex align-items-center mb-2">
              <SteppedNumber>6</SteppedNumber>
              <SteppedTitle className="mx-2">Entire Package Details</SteppedTitle>
            </div>
            <Row className="form-row mt-3">
              <Col className="form-group col-xl-6 col-md-6">
                <label htmlFor="Description">Description</label>
                <input
                    name="description"
                    className="form-control"
                    type="text"
                    maxLength={80}
                />
              </Col>

              <Col className="form-group col-xl-3 col-md-6">
                <label htmlFor="Declared Value">Declared Value</label>
                <input
                    name="declared_value"
                    className="form-control"
                    type="number"
                    placeholder=""
                    maxLength={45}
                />
              </Col>

              <Col className="form-group col-xl-3 col-md-6">
                <label htmlFor="Sender Postal">Payment Method</label>
                <select name="payment_method" className="form-select">
                  <option value="">Select Payment Method</option>
                  <option value="Cash">Cash</option>
                  <option value="Card Payment">Card Payment</option>
                  <option value="Direct Transfer">Direct Transfer</option>
                  <option value="Wallet">Wallet</option>
                </select>
              </Col>
            </Row>
          </section>

          <div className="d-flex flex-row my-5 justify-content-end">
            <SubmitButton type="submit" className="confirm-button">
              {isCreatingShipment === false ? (
                  <span className="button-text">PROCESS SHIPMENT</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
            </SubmitButton>
          </div>
        </div>
        }
      </form>
    </div>
  );
};

export default CreateInterstateTerminalShipmentForm;
